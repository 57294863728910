// Game.jsx
import React, { useState } from 'react'
import Experience from '../modules/Experience'


import texts from '../../texts'
import StartScreen from '../organisms/StartScreen'
import EndScreen from '../organisms/EndScreen'

export default function Game() {

  // Zustände
  const [isGameStarted, setIsGameStarted] = useState(false)
  const [isGameFinished, setIsGameFinished] = useState(false)

  const startGame = () => {
    setIsGameStarted(true)
  }

  const handleGameFinished = () => {
    setIsGameFinished(true)
  }

  return (
    <>

      {!isGameStarted && (
        <StartScreen onStart={startGame} />
      )}

      {isGameStarted && !isGameFinished && (
        
          <Experience
            buttons={texts.buttons}
            game={texts.game}
            onGameFinished={handleGameFinished}
          />
        
      )}

      {isGameFinished && (
        <EndScreen message="Alle Level abgeschlossen!" />
      )}
    </>
  )
}
