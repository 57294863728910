import React, { useEffect, useRef, useState } from "react";


export default function VisitorManager({
    money,
    setMoney,
    pricePerLemonade,
    purchaseChance,
    visitorsPerClick,
    setTotalVisitors,
    setCurrentVisitors,
    totalSales,
    setTotalSales,
    totalRevenue,
    setTotalRevenue,
    totalViews,
    setTotalViews,
    noPurchaseVisitors,
    setNoPurchaseVisitors,
    permanentVisitors,
}) {
    const visitorsRef = useRef([]);
    const [isPressed, setIsPressed] = useState(false); // Zustand für die Animation

    // Besucher hinzufügen
    const addVisitor = (count) => {
        const newVisitors = Array.from({ length: count }, () => Date.now() + Math.random());
        visitorsRef.current = [...visitorsRef.current, ...newVisitors];
        setTotalVisitors((prev) => prev + count);

        // Entferne Besucher nach 10 Sekunden
        newVisitors.forEach((visitorId) => {
            setTimeout(() => {
                visitorsRef.current = visitorsRef.current.filter((id) => id !== visitorId);
                setCurrentVisitors(visitorsRef.current.length);
            }, 10000);
        });

        setCurrentVisitors(visitorsRef.current.length);
    };

    // Permanente Besucher-Logik
    useEffect(() => {
        if (permanentVisitors > 0) {
            const interval = setInterval(() => {
                addVisitor(permanentVisitors);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [permanentVisitors]);

    // Verkaufslogik
    useEffect(() => {
        const interval = setInterval(() => {
            if (visitorsRef.current.length > 0) {
                let sales = 0;
                let nonBuyers = 0;

                visitorsRef.current.forEach(() => {
                    if (Math.random() <= purchaseChance) {
                        sales++;
                        setMoney((prev) => prev + pricePerLemonade);
                    } else {
                        nonBuyers++;
                    }
                    setTotalViews((prev) => prev + 1);
                });

                setTotalSales((prev) => prev + sales);
                setTotalRevenue((prev) => prev + sales * pricePerLemonade);
                setNoPurchaseVisitors((prev) => prev + nonBuyers);
            }

            setCurrentVisitors(visitorsRef.current.length);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // Leertaste-Logik
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === "Space") {
                event.preventDefault(); // Verhindere das Scrollen der Seite bei Leertaste
                setIsPressed(true); // Aktiviert die Animation
                addVisitor(visitorsPerClick);

                // Entferne die Animation nach 150ms
                setTimeout(() => {
                    setIsPressed(false);
                }, 150);
            }
        };

        // Event-Listener hinzufügen
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup des Event-Listeners
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [visitorsPerClick]);

    return (
        <div>
            {/* Webseite mit gedrückter Animation */}
            <div
                className={`webpage ${isPressed ? "pressed" : ""}`}
                style={{
                    height: "400px",
                    width: "720px",
                    backgroundColor: "grey",
                    margin: "10px auto",
                    borderRadius: "10px",
                }}
            >
                <p style={{ color: 'white'}}>Press <strong>Spacebar</strong> to add {visitorsPerClick} visitor(s).</p>
            </div>

            
        </div>
    );
}
