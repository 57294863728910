// Button.jsx
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Button({ href, type, children, style }) {
  const navigate = useNavigate()

  const handleClick = () => {
    console.log(href)
    if (href) {
      navigate(href)
    }
  }
  
  return (
    <button
      onClick={handleClick}
      className={type} // Typ kann verwendet werden, um CSS-Klassen anzuwenden, z.B., "primary"
      style={{
        fontSize: '16px',
        zIndex: 10,
        cursor: 'pointer',
        ...style, // Übernehmen von zusätzlichem benutzerdefinierten Styling
      }}
    >
      {children || 'Zurück'}
    </button>
  )
}
