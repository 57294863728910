import React from "react";

export default function Statistics({
  money,
  totalSales,
  totalRevenue,
  totalVisitors,
  totalViews,
  noPurchaseVisitors,
  milestoneMessage,
  currentVisitors,
}) {
return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
        <h1 style={{ fontSize: '24px', marginBottom: '10px' }}>Money: {money}€</h1>
        <p style={{ fontSize: '18px', marginBottom: '5px' }}>Aktuelle Besucher: {currentVisitors}</p>
        <p style={{ fontSize: '18px', marginBottom: '5px' }}>Verkaufte Flaschen: {totalSales}</p>
        <p style={{ fontSize: '18px', marginBottom: '5px' }}>Total Revenue: ${totalRevenue}</p>
        <p style={{ fontSize: '18px', marginBottom: '5px' }}>Total Visitors: {totalVisitors}</p>
        <p style={{ fontSize: '18px', marginBottom: '5px' }}>Conversion Rate: {((totalSales / totalViews) * 100 || 0).toFixed(2)}%</p>
        {milestoneMessage && <p style={{ fontSize: '18px', marginTop: '10px', color: '#007BFF' }}>{milestoneMessage}</p>}
    </div>
);
}
