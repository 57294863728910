import React, { useState, useEffect, useRef } from 'react';

// ---------------------------------------------------

const calculateRadius = (percentage) => {
  return Math.round((window.innerWidth * percentage) / 100);
};


// ---------------------------------------------------
// Upgrades Json
// ---------------------------------------------------

const spawnRateUpgrades = [
  {
    level: 0,
    cost: 0,
    spawnRate: 1500, // in ms
    label: 'Bonus Level 0',
    text: 'Bonus Marketing',
  },
  {
    level: 1,
    cost: 3,
    spawnRate: 1000,
    label: 'Bonus Level 1',
    text: 'Bonus Marketing',
  },
  {
    level: 2,
    cost: 35,
    spawnRate: 700,
    label: 'Bonus Level 2',
    text: 'Bonus Marketing',
  },
  {
    level: 3,
    cost: 100,
    spawnRate: 450,
    label: 'Bonus Level 3',
    text: 'Bonus Marketing',
  },
  {
    level: 4,
    cost: 200,
    spawnRate: 200,
    label: 'Bonus Level 4',
    text: 'Bonus Marketing',
  },
  {
    level: 5,
    cost: 400,
    spawnRate: 100,
    label: 'Bonus Level 5',
    text: 'Bonus Marketing',
  },

];

const speedUpgrades = [
  {
    level: 0,
    cost: 0,
    speed: 1.0, // Faktor
    label: 'Countdown',
    text: 'Nutze Druck und Dringlichkeit damit die Besucher sich schneller auf der Seite bewegen.',
  },
  {
    level: 1,
    cost: 10,
    speed: 1.5,
    label: 'Countdown Level 1',
    text: 'Nutze Druck und Dringlichkeit damit die Besucher sich schneller auf der Seite bewegen.',
  },
  {
    level: 2,
    cost: 50,
    speed: 2.0,
    label: 'Countdown Level 2',
    text: 'Nutze Druck und Dringlichkeit damit die Besucher sich schneller auf der Seite bewegen.',
  },
  {
    level: 3,
    cost: 150,
    speed: 2.5,
    label: 'Countdown Level 3',
    text: 'Nutze Druck und Dringlichkeit damit die Besucher sich schneller auf der Seite bewegen.',
  },
  {
    level: 4,
    cost: 300,
    speed: 3.0,
    label: 'Countdown Level 4',
    text: 'Nutze Druck und Dringlichkeit damit die Besucher sich schneller auf der Seite bewegen.',
  }
];

const gravitationUpgrades = [
  {
    level: 0,
    cost: 0,
    gravityRadius: 100,
    label: 'Gefälschte Bewertungen',
    text: 'Nutze soziale Bestätigung damit der Anziehungsradius des Kauf-Buttons vergrößert wird.',
  },
  {
    level: 1,
    cost: 20,
    gravityRadius: 300,
    label: 'Gefälschte Bewertungen Level 1',
    text: 'Nutze soziale Bestätigung damit der Anziehungsradius des Kauf-Buttons vergrößert wird.',
  },
  {
    level: 2,
    cost: 75,
    gravityRadius: 400,
    label: 'Gefälschte Bewertungen Level 2',
    text: 'Nutze soziale Bestätigung damit der Anziehungsradius des Kauf-Buttons vergrößert wird.',
  },
  {
    level: 3,
    cost: 250,
    gravityRadius: 600,
    label: 'Gefälschte Bewertungen Level 3',
    text: 'Nutze soziale Bestätigung damit der Anziehungsradius des Kauf-Buttons vergrößert wird.',
  },
  {
    level: 4,
    cost: 450,
    gravityRadius: 800,
    label: 'Gefälschte Bewertungen Level 4',
    text: 'Nutze soziale Bestätigung damit der Anziehungsradius des Kauf-Buttons vergrößert wird.',
  }
];

const desinformationUpgrades = [
  {
    level: 0,
    cost: 0,
    gravityStrength: 0.01,
    label: 'Irreführende Informationen',
    text: 'Nutze Schnäppchen Mentalität und Knappheit um Besucher stärker zum Kauf-Button zu ziehen.',
  },
  {
    level: 1,
    cost: 3,
    gravityStrength: 0.02,
    label: 'Irreführende Informationen Level 1',
    text: 'Nutze Schnäppchen Mentalität und Knappheit um Besucher stärker zum Kauf-Button zu ziehen.',
  },
  {
    level: 2,
    cost: 35,
    gravityStrength: 0.03,
    label: 'Irreführende Informationen Level 2',
    text: 'Nutze Schnäppchen Mentalität und Knappheit um Besucher stärker zum Kauf-Button zu ziehen.',
  },
  {
    level: 3,
    cost: 200,
    gravityStrength: 0.05,
    label: 'Irreführende Informationen Level 3',
    text: 'Nutze Schnäppchen Mentalität und Knappheit um Besucher stärker zum Kauf-Button zu ziehen.',
  },
  {
    level: 4,
    cost: 400,
    gravityStrength: 0.05,
    label: 'Irreführende Informationen Level 4',
    text: 'Nutze Schnäppchen Mentalität und Knappheit um Besucher stärker zum Kauf-Button zu ziehen.',
  }
];

// ---------------------------------------------------
// Upgrade Komponenten
// ---------------------------------------------------

function UpgradeComponent({
  upgrades,
  currentLevel,
  onUpgrade,
  count,
  labelPrefix,
  upgradeType
}) {
  const currentUpgrade = upgrades.find(u => u.level === currentLevel);
  const nextUpgrade = upgrades.find(u => u.level === currentLevel + 1);

  if (!currentUpgrade) {
    return <p>Kein Upgrade für das aktuelle Level verfügbar.</p>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',

        gap: 12,
        width: '100%',
        backgroundColor: 'white',
        padding: '20px 16px 8px 16px',
        borderRadius: 4,
        border: `1px solid rgb(224, 224, 224)`,
        zIndex: 90,
        boxShadow: '0px 1px 1px hsl(0deg 0% 0% / 0.075), 0px 2px 2px hsl(0deg 0% 0% / 0.075), 0px 4px 4px hsl(0deg 0% 0% / 0.075)',
        maxWidth: '400px',

      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <p style={{ letterSpacing: '0.05rem', fontWeight: 600}}>{currentUpgrade.label}</p>
        <span style={{ color: nextUpgrade && count >= nextUpgrade.cost ? COLORS.primary : 'gray' }}>
          <b style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', justifyItems: 'center', alignItems: 'center' }}>
            {nextUpgrade ? (
              <>
                {nextUpgrade.cost}
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984M12 6V18M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke={nextUpgrade && count >= nextUpgrade.cost ? COLORS.primary : 'gray'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </>
            ) : (
              '-'
            )}
          </b>
        </span>
      </div>
      <p style={{ fontSize: '14px' }}>{currentUpgrade.text}</p>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {nextUpgrade ? (

          <button
            onClick={onUpgrade}
            className="buttonUpgrade"
            disabled={count < nextUpgrade.cost}
            style={{
              cursor: count >= nextUpgrade.cost ? 'pointer' : 'not-allowed',
              height: 48,
              padding: '0 16px',
              border: 'none',
              backgroundColor: count >= nextUpgrade.cost ? '#009951' : '#e6f5ee',
              color: count >= nextUpgrade.cost ? '#fff' : 'gray',
              borderRadius: 4,
            }}
            
          >
            <span style={{ margin: '0px 12px', fontSize: '14px', letterSpacing: '0.05rem', fontWeight: 500 }}>
              {currentUpgrade.level === 0 ? 'Freischalten' : 'Weiterentwickeln'}
            </span>
          </button>
        ) : (

          <button

            disabled={true}
            style={{
              cursor: 'not-allowed',
              padding: '0 16px',
              width: 'auto',
              height: 48,
              border: 'none',
              backgroundColor: '#fbeaea',
              color: 'gray',
              borderRadius: 4,
            }}

          >
            <span style={{ margin: '0px 12px', fontSize: '14px', letterSpacing: '0.05rem', fontWeight: 500 }}>
              Maximales Level erreicht
            </span>

          </button>
        )}
      </div>
    </div>
  );
}

function MarketingComponent({
  upgrades,
  currentLevel,
  onUpgrade,
  count,
  speedLevel,
  gravitationLevel,
  desinformationLevel,
}) {
  const currentUpgrade = upgrades.find(u => u.level === currentLevel);
  const nextUpgrade = upgrades.find(u => u.level === currentLevel + 1);
  const [isVisible, setIsVisible] = useState(true);

  // Logik: Button nur anzeigen, wenn alle Levels gleich sind und kleiner oder gleich 5
  useEffect(() => {
    if (
      speedLevel === gravitationLevel &&
      gravitationLevel === desinformationLevel &&
      speedLevel <= 5
    ) {
      setIsVisible(true); // Zeigt den Button an
    } else {
      setIsVisible(false); // Versteckt den Button
    }
  }, [speedLevel, gravitationLevel, desinformationLevel]);


  if (!currentUpgrade) {
    return <p>Kein Upgrade für das aktuelle Level verfügbar.</p>;
  }


  return (
    <div
      className='marketing-component'
      style={{
        display: isVisible ? 'inline-flex' : 'none',
        flexDirection: 'column',

        width: '100%',
        // backgroundColor: 'white',
        padding: '20px 16px 8px 16px',
        // borderRadius: 4,
        // border: `1px solid rgb(224, 224, 224)`,
        zIndex: 90,
        // boxShadow: '0px 1px 1px hsl(0deg 0% 0% / 0.075), 0px 2px 2px hsl(0deg 0% 0% / 0.075), 0px 4px 4px hsl(0deg 0% 0% / 0.075)',
        maxWidth: '400px',

      }}
    >

      
        {nextUpgrade ? (

          <button
            onClick={() => {
              onUpgrade(); // Führt die Upgrade-Funktion aus
              setIsVisible(false); // Versteckt den Button

            }}
            className='marketing-button'
            style={{
              cursor: 'pointer',
              height: 64,
              padding: '0 32px',
              border: 'none',
              backgroundColor: '#009951',
              color: '#fff',
              borderRadius: 4,
            }}
          >
            <span style={{ fontSize: '12px', textAlign: 'center' }}>
              <p style={{ fontSize: '14px' }}>{currentUpgrade.text}</p>
              <p style={{ fontSize: '14px', letterSpacing: '0.05rem', fontWeight: 500 }}>Erhalte mehr Besucher</p>
              {/* <b>{currentUpgrade.level === 0 ? 'Aktivieren' : 'Weiterentwickeln'}</b> */}
            </span>
          </button>
        ) : (

          <button

            disabled={true}
            style={{
              cursor: 'not-allowed',
              padding: '0 16px',
              width: 'auto',
              height: 48,
              border: 'none',
              backgroundColor: '#009951',
              color: 'gray',
              borderRadius: 4,
            }}

          >
            <span style={{ margin: '0px 12px', fontSize: '12px' }}>
              <b>Maximales Level erreicht</b>
            </span>

          </button>
        )}
      
    </div>
  );
}

// ---------------------------------------------------
// Upgrade Infos
// ---------------------------------------------------

const levelsContent = {
  speedLevel: [
    { title: "Angebot noch 3 Tage", text: "Der Timer wirkt als sanfte Erinnerung, steigert jedoch durch seine Präsenz die Aufmerksamkeit für das Angebot." },
    { title: "Limitiert noch 16 Stunden", text: "Besucher fühlen sich leicht unter Druck gesetzt, da die Zeit knapper wird, was die kognitive Belastung und Entscheidungsdrang erhöt." },
    { title: "Nur noch 3 Stunden", text: "Besucher spüren starken Zeitdruck und sind weniger geneigt, ihre Entscheidungen kritisch zu hinterfragen. Die Verlustangst wird aktiv." },
    { title: "Nur noch 30 Sekunden", text: "Besucher reagieren reflexartig, um den „Verlust“ zu vermeiden. Die Entscheidungsfindung findet fast nur noch impulsiv statt." },
  ],
  gravitationLevel: [
    { title: "\„Ein tolles Produkt – ich bin zufrieden! Würde ich mir jederzeit wieder kaufen.\“", text: "Neutral-positive Empfehlung, die Vertrauen schafft, ohne Druck auszuüben." },
    { title: "\„Das war die beste Entscheidung! Tolle Qualität, und es wurde super schnell geliefert.\“", text: "Hebt spezifische Vorteile hervor und verstärkt die Attraktivität des Produkts." },
    { title: "Weiterempfehlungsquote 98%", text: "Vermittelt soziale Bestätigung, die zum Kauf motiviert." },
    { title: "Autoritätperson mit Dr. Titel", text: "Besucher empfinden die Empfehlung von Personen mit hohem Bildungsstand als besonders seriös und vertrauenswürdig." },
  ],
  desinformationLevel: [
    { title: "Falscher Referenzpreis", text: "Ein künstlich hoher Ausgangspreis lässt das Angebot als Schnäppchen erscheinen. Der wahrgenommene Wert verleitet den Besucher zu impulsiven Käufen." },
    { title: "Falsche Knappheit Nur 3 auf Lager", text: "Dies vermittelt höchste Dringlichkeit und erzeugt den Eindruck, dass eine sofortige Handlung die einzige Chance ist, das Produkt zu sichern." },
    { title: "5 Tsd. verkauft", text: "Die Zahl der Verkäufe wird genutzt, um Vertrauen aufzubauen und den Eindruck zu erwecken, dass das Produkt beliebt ist." },
    { title: "Falscher Sale von 80%", text: "Der Ausgangspreis wurde nochmals erhöt. Es wird das Gefühl vermittelt, Geld zu sparen. Das Belohnungszentrum im Gehirn wird aktiviert." },
  ],
};

function getLevelContent(levelType, level) {
  const [isVisible, setIsVisible] = useState(true);
  const [fadein, setFadein] = useState(false);

  const handleVerstandenClick = () => {
    setIsVisible(false);
  };

  const content = levelsContent[levelType]?.[level - 1];

  // Reset the visibility when levelType or level changes
  useEffect(() => {
    setIsVisible(false);
    setFadein(false);

    const timeout = setTimeout(() => {
      setIsVisible(true);

      // Verzögerung für fade-in
      setTimeout(() => {
        setFadein(true);
      }, 50); // Kleine Verzögerung, um sicherzustellen, dass die Animation ausgelöst wird
    }, 2500);

    return () => clearTimeout(timeout); // Timeout bereinigen
  }, [levelType, level]);

  return content && isVisible ? (
    <div style={{
      width: '100%',
      padding: '24px 24px 0',
      backgroundColor: 'white',
      borderRadius: 8,
      display: isVisible ? 'block' : 'none',
      border: `1px solid rgb(224, 224, 224)`,
      boxShadow: '0px 1px 1px hsl(0deg 0% 0% / 0.075), 0px 2px 2px hsl(0deg 0% 0% / 0.075), 0px 4px 4px hsl(0deg 0% 0% / 0.075), 0px 8px 8px hsl(0deg 0% 0% / 0.075), 0px 16px 16px hsl(0deg 0% 0% / 0.075)'
    }}
      className={`level-content ${fadein ? 'fade-in' : ''}`}
    >
      <p style={{ marginBottom: 12 }}><b>{content.title}</b></p>
      <p style={{ marginBottom: 24 }}>{content.text}</p>
      <button
        onClick={handleVerstandenClick}
        style={{
          cursor: 'pointer',
          color: 'black',
          textDecoration: 'none',
          backgroundColor: '#f9b066',
          onHover: '#f57c00',
          padding: '0 16px',
          marginBottom: 8,
          height: 48,
          border: 'none',
          borderRadius: 4,
        }}>


        <span style={{ margin: '0px 12px', fontSize: '14px', letterSpacing: '0.05rem', fontWeight: 500 }}>
          Verstanden
        </span>
      </button>
    </div>
  ) : null;
}



// Farb- und Abstandskonstanten
const COLORS = {
  primary: '#1A73E8',
  primaryLightOpacity: 'rgba(26, 115, 232, 0.30)',
  primaryMediumOpacity: 'rgba(26, 115, 232, 0.50)',
  gradientLight: 'linear-gradient(180deg, rgba(26, 115, 232, 0.01) 0%, rgba(26, 115, 232, 0.10) 100%)',
  patternGradientLight: 'linear-gradient(180deg, rgba(211, 47, 47, 0.01) 0%, rgba(211, 47, 47, 0.10) 100%)',
  white: '#FFFFFF',
  pattern: '#D32F2F'
};

const SPACING = {
  padding: 16,
  gapLarge: 24,
  gapMedium: 12,
  gapSmall: 8,
  gapXSmall: 4,
  borderRadius: 8,
  borderRadiusSmall: 4,
  productHeight: 12,
};

// Haupt-Container
const containerStyle = {
  background: COLORS.gradientLight,
  width: '600px',
  padding: SPACING.padding,
  paddingBottom: 40,
  display: 'inline-flex',
  flexDirection: 'column',
  gap: SPACING.gapLarge,
  borderRadius: SPACING.borderRadius,
  border: `2px ${COLORS.primary} solid`,
};

// Produktzeile oben
const productRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

// Balken links oben
const barLeftStyle = {
  background: COLORS.primaryMediumOpacity,
  height: `${SPACING.productHeight}px`,
  width: '50px',
};

// Balken-Gruppe rechts oben
const barGroupRightStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
};

// Kleiner Balken (3 Varianten)
const smallBar1Style = {
  background: COLORS.primaryLightOpacity,
  height: `${SPACING.productHeight}px`,
  width: '30px',
};
const smallBar2Style = {
  background: COLORS.primaryLightOpacity,
  height: `${SPACING.productHeight}px`,
  width: '50px',
};
const smallBar3Style = {
  background: COLORS.primaryLightOpacity,
  height: `${SPACING.productHeight}px`,
  width: '40px',
};

// Produktabschnitt mittig
const productSectionStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: SPACING.gapSmall,
};

// Produktbild
const productPictureStyle = {
  background: COLORS.gradientLight,
  width: '100%',
  borderRadius: SPACING.borderRadius,
  border: `2px ${COLORS.primary} solid`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

// Produktbeschreibung
const productDescriptionStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: SPACING.gapMedium,
};

// Titelzeile (Name & Preis)
const titleGroupStyle = {
  margin: 0,
  color: COLORS.primary,
};
const darktitleGroupStyle = {
  margin: 0,
  color: COLORS.pattern,
};

// Beschreibungstexte (Dummy-Balken)
const descriptionGroupStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: SPACING.gapXSmall,
};
const dummyText1Style = {
  background: COLORS.primaryLightOpacity,
  height: '8px',
  width: '85%',
};
const dummyText2Style = {
  background: COLORS.primaryLightOpacity,
  height: '8px',
  width: '75%',
};
const dummyText3Style = {
  background: COLORS.primaryLightOpacity,
  height: '8px',
  width: '80%',
};

// Kaufen-Button
const buyButtonStyle = {
  background: COLORS.primary,
  width: '100%',
  height: '40px',
  textAlign: 'center',
  borderRadius: SPACING.borderRadiusSmall,
  color: COLORS.white,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background-color 0.3s ease', // Sanfter Übergang
};

// Abschnittsüberschrift
const sectionLabelStyle = {
  color: COLORS.primary,
  margin: 0,
  marginBottom: `${SPACING.gapMedium}px`,
};

// Grid für Bewertungen
const sectionContentStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: '1fr',
  gridColumnGap: `${SPACING.gapSmall}px`,
  gridRowGap: '0px',
};

// Einzelne Bewertungskarte
const sectionItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: SPACING.gapXSmall,
  alignItems: 'center',
};

// Bild in Karte
const pictureStyle = {
  width: '64px',
  height: '64px',
  background: COLORS.gradientLight,
  borderRadius: 32,
  border: `2px ${COLORS.primary} solid`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const patternPictureStyle = {
  width: '64px',
  height: '64px',
  background: COLORS.patternGradientLight,
  borderRadius: 32,
  border: `2px ${COLORS.pattern} solid`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

// Name
const nameStyle = {
  color: COLORS.primary,
  fontWeight: 600,
};

const patternNameStyle = {
  color: COLORS.pattern,
  fontWeight: 600,
};

// Sterne-Bewertung
const ratingStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
};

// Produktbeschreibung unten
const productDescriptionBottomStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: SPACING.gapXSmall,
};




export default function FlexTest() {

  // ---------------------------------------------------
  // 1) STATES (Geschwindigkeit, Spawnrate, Gravitation)
  // ---------------------------------------------------
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);         // Kollisionen / Treffer in Box

  const [currentStep, setCurrentStep] = useState(0);
  const [spawnRateLevel, setSpawnRateLevel] = useState(0);
  const [speedLevel, setSpeedLevel] = useState(0);
  const [gravitationLevel, setGravitationLevel] = useState(0);
  const [desinformationLevel, setDesinformationLevel] = useState(0);

  const [speed, setSpeed] = useState(1);
  const [spawnRate, setSpawnRate] = useState(1500);
  const [gravityStrength, setGravityStrength] = useState(0.02);
  const [gravityRadius, setGravityRadius] = useState(200);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const hasShownRef = useRef({}); // Speichert, ob ein Level bereits angezeigt wurde


  const boxRef = useRef(null);

  const toolTip1 = useRef(null);

  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (speedLevel === 4) {
      const interval = setInterval(() => {
        setTimer((prev) => (prev === 0 ? 30 : prev - 1));
      }, 1000);

      return () => clearInterval(interval); // Cleanup
    }
  }, [speedLevel]);

  // ---------------------------------------------------
  // 2) Funktion zum Erzeugen neuer "Cursor" (SVGs)
  // ---------------------------------------------------
  function spawnSVG() {
    const svgDiv = document.createElement('div');
    svgDiv.className = 'svg-icon';
    svgDiv.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path d="M4 0l16 12.279-6.951 1.17 
                    4.325 8.817-3.596 1.734-4.35-8.879
                    -5.428 4.702z"/>
        </svg>
        `;

    let x = Math.random() * (window.innerWidth - 50);
    let y = Math.random() * (window.innerHeight - 50);

    svgDiv.style.position = 'absolute';
    svgDiv.style.left = `${x}px`;
    svgDiv.style.top = `${y}px`;
    document.body.appendChild(svgDiv);

    // Zufällige Startrichtung
    let dx = Math.random() < 0.5 ? -1 : 1;
    let dy = Math.random() < 0.5 ? -1 : 1;

    // Entfernen nach 60s (falls nicht vorher kollidiert)
    const removeTimer = setTimeout(() => {
      if (svgDiv.isConnected) {
        svgDiv.remove();
      }
    }, 40000);

    // Animationsschleife via requestAnimationFrame
    function moveSVG() {
      // "Schlängeln": Leichte Zufalls-Abweichung des Winkels
      let angle = Math.atan2(dy, dx);
      angle += (Math.random() - 0.5) * 0.3;
      dx = Math.cos(angle);
      dy = Math.sin(angle);

      // Bewegung je nach speed
      x += dx * (speed + (Math.random() - 0.6));
      y += dy * (speed + (Math.random() - 0.6));

      // Gravitation (falls Box vorhanden)
      const boxEl = boxRef.current;
      if (boxEl) {
        const boxRect = boxEl.getBoundingClientRect();
        const cx = boxRect.left + boxRect.width / 2;
        const cy = boxRect.top + boxRect.height / 2;

        let gx = cx - x;
        let gy = cy - y;
        const dist = Math.sqrt(gx * gx + gy * gy);

        // Innerhalb von gravityRadius => Anziehung
        if (dist > 20 && dist < gravityRadius) { // Mindestentfernung für Gravitation
          const force = gravityStrength * (1 - dist / gravityRadius);

          // Gravitation stärker bei nahen Distanzen, abgeschwächt bei großen
          gx /= dist;
          gy /= dist;

          dx += gx * Math.min(force, 0.2); // Begrenzung der maximalen Änderung
          dy += gy * Math.min(force, 0.2);
        }

      }

      // Rand-Kollision (Bounce)
      if (x <= 0 || x >= window.innerWidth - 50) dx *= -1;
      if (y <= 0 || y >= window.innerHeight - 50) dy *= -1;

      // Position updaten
      svgDiv.style.left = `${x}px`;
      svgDiv.style.top = `${y}px`;


      if (boxEl) {
        const boxRect = boxEl.getBoundingClientRect();
        const svgRect = svgDiv.getBoundingClientRect();
        if (
          svgRect.left < boxRect.right &&
          svgRect.right > boxRect.left &&
          svgRect.top < boxRect.bottom &&
          svgRect.bottom > boxRect.top
        ) {
          clearTimeout(removeTimer);
          svgDiv.remove();
          setCount((prev) => prev + 1);
          setTotalCount((prev) => prev + 1);

          // Animation auslösen
          boxEl.classList.add('box-animate');
          setTimeout(() => {
            boxEl.classList.remove('box-animate'); // Entferne die Klasse nach der Animation
          }, 300); // Dauer der Animation
          return;
        }
      }

      // Falls entfernt -> kein Loop mehr
      if (!svgDiv.isConnected) {
        clearTimeout(removeTimer);
        return;
      }

      // Nächstes Frame
      requestAnimationFrame(moveSVG);
    }
    moveSVG();
  }

  function removeAllSVGs() {
    const svgElements = document.querySelectorAll('.svg-icon');
    svgElements.forEach((svg) => svg.remove());
  }

  function handleRotate() {
    setIsRotating(true); // Setzt den State auf true, um die Rotation zu starten
    // Entferne die Klasse nach der Animation, damit sie erneut ausgelöst werden kann
    setTimeout(() => {
      setIsRotating(false); // Setzt den State zurück, damit die Animation erneut ausgelöst werden kann
    }, 1000); // Dauer der Animation (1 Sekunde)
  }

  // ---------------------------------------------------
  // 3) useEffect: Spawnt Cursor in regelmäßigen Abständen
  // ---------------------------------------------------
  useEffect(() => {
    // Alle (100 / speed) ms wird geprüft, ob ein Cursor erscheint
    // => Höhere speed => kürzeres Intervall => mehr Cursor
    let intervalId = setInterval(() => {
      // Nur dann spawnen, wenn Math.random() > spawnRate
      // (z.B. spawnRate = 0.8 => 20% Wahrscheinlichkeit pro Intervall)
      spawnSVG();

    }, spawnRate);

    return () => clearInterval(intervalId);
  }, [speed, spawnRate, gravityStrength, gravityRadius]);

  // ---------------------------------------------------
  // Sterne
  // ---------------------------------------------------

  function Star({ filled, color }) {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        {filled && (
          <path
            d="M5.7473 1.35691C5.85058 1.14763 6.149 1.14763 6.25228 1.35691L7.58928 4.06597C7.6303 4.14907 7.70958 4.20667 7.80129 4.22L10.7909 4.65441C11.0219 4.68797 11.1141 4.97178 10.947 5.13468L8.78365 7.24339C8.71729 7.30808 8.687 7.40128 8.70267 7.49261L9.21336 10.4702C9.25281 10.7002 9.01138 10.8756 8.80482 10.767L6.13082 9.36117C6.04879 9.31805 5.95079 9.31805 5.86877 9.36117L3.19476 10.767C2.9882 10.8756 2.74677 10.7002 2.78623 10.4702L3.29691 7.49261C3.31258 7.40128 3.2823 7.30808 3.21594 7.24339L1.05262 5.13468C0.885508 4.97178 0.977724 4.68797 1.20867 4.65441L4.1983 4.22C4.29001 4.20667 4.36929 4.14907 4.4103 4.06597L5.7473 1.35691Z"
            fill={color}
            fillOpacity="0.5"
          />
        )}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.29899 1.13562C5.58568 0.554727 6.41402 0.554727 6.70071 1.13562L7.98689 3.74171L10.8629 4.15961C11.5039 4.25276 11.7599 5.04056 11.296 5.49272L9.21495 7.52128L9.70622 10.3856C9.81573 11.0241 9.14559 11.511 8.57221 11.2095L5.99985 9.85718L3.42749 11.2095C2.85411 11.511 2.18397 11.0241 2.29348 10.3856L2.78476 7.52128L0.703675 5.49272C0.239802 5.04056 0.495773 4.25276 1.13683 4.15961L4.01281 3.74171L5.29899 1.13562ZM5.99985 1.97508L4.85873 4.28725C4.74488 4.51793 4.52482 4.67781 4.27026 4.7148L1.71862 5.08557L3.565 6.88535C3.74921 7.0649 3.83326 7.3236 3.78978 7.57714L3.35391 10.1185L5.63616 8.91861C5.86384 8.79891 6.13586 8.79891 6.36355 8.91861L8.64579 10.1185L8.20992 7.57714C8.16644 7.3236 8.2505 7.0649 8.4347 6.88535L10.2811 5.08557L7.72945 4.7148C7.47488 4.67781 7.25482 4.51793 7.14098 4.28725L5.99985 1.97508Z"
          fill={color}
        />
      </svg>
    );
  }



  // ---------------------------------------------------
  // Bewertung
  // ---------------------------------------------------

  function RatingCard({ name, fullStars, maxStars = 5, color }) {
    const stars = Array.from({ length: maxStars }, (_, index) =>
      index < fullStars ? (
        <Star key={`star-filled-${index}`} filled={true} color={color} />
      ) : (
        <Star key={`star-empty-${index}`} filled={false} color={color} />
      )
    );


    return (
      <div className="SectionItem" style={sectionItemStyle}>
        <div className="Picture" style={color === COLORS.primary ? pictureStyle : patternPictureStyle}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.47559 39C9.12641 34.5785 14.9361 31.5524 24.0001 31.5524C33.0641 31.5524 38.8739 34.5785 41.5247 39M31.2001 16.2C31.2001 20.1765 27.9766 23.4 24.0001 23.4C20.0237 23.4 16.8001 20.1765 16.8001 16.2C16.8001 12.2235 20.0237 9 24.0001 9C27.9766 9 31.2001 12.2235 31.2001 16.2Z"
              stroke={color}
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="Name" style={color === COLORS.primary ? nameStyle : patternNameStyle}>{name}</div>
        <div className="Rating" style={ratingStyle}>
          {stars}
        </div>
      </div>
    );
  }


  const debugMode = window.location.hash === '#debug';
  const geldMode = window.location.hash === '#geld';

  //  console.log(hasShownRef)





  // ---------------------------------------------------
  //  Return
  // ---------------------------------------------------



  return (
    <>
      <div style={{ userSelect: 'none', webUserSelect: 'none' }}>
        <style>{globalCss}</style>
        {debugMode && (
          <>


            {/* SLIDER: speed */}
            <div style={{ marginBottom: 10 }}>
              <label>Geschwindigkeit (speed): {speed}</label>
              <br />
              <input
                type="range"
                min="0.5"
                max="4"
                step="0.1"
                value={speed}
                onChange={(e) => setSpeed(Number(e.target.value))}
              />
            </div>

            {/* SLIDER: spawnRate */}
            <div style={{ marginBottom: 10 }}>
              <label>Spawnrate: {spawnRate}</label>
              <br />
              <input
                type="range"
                min="50"
                max="10000"
                step="10"
                value={spawnRate}
                onChange={(e) => setSpawnRate(Number(e.target.value))}
              />
            </div>

            {/* SLIDER: gravityStrength */}
            <div style={{ marginBottom: 10 }}>
              <label>Gravitationsstärke: {gravityStrength}</label>
              <br />
              <input
                type="range"
                min="0"
                max="0.05"
                step="0.001"
                value={gravityStrength}
                onChange={(e) => setGravityStrength(Number(e.target.value))}
              />
            </div>

            {/* SLIDER: gravityRadius */}
            <div style={{ marginBottom: 10 }}>
              <label>Gravitationsradius: {gravityRadius}</label>
              <br />
              <input
                type="range"
                min="50"
                max="1600"
                step="10"
                value={gravityRadius}
                onChange={(e) => setGravityRadius(Number(e.target.value))}
              />
            </div>

            <button onClick={() => {
              setCount(0)
              setCurrentStep(0)

              setSpawnRate(5000)
              setSpeed(0.5)
              setGravityRadius(50)
              setGravityStrength(0.001)

            }}>Reset</button>

            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 10, width: 80 }}>
              {/* Button CounterUpgrade diplay inline*/}
              <button onClick={() => {
                setCurrentStep(1)

                setSpawnRate(4500)
                setSpeed(0.7)
                setGravityRadius(100)
                setGravityStrength(0.009)

                // console.log(toolTip1)
                toolTip1.current.classList.add('tooltip-animate');
              }}>Upgrade 1</button>

              {/* Button Upgrade 2*/}
              <button onClick={() => {
                setCurrentStep(2)

                setSpawnRate(4000)
                setSpeed(0.9)
                setGravityRadius(200)
                setGravityStrength(0.01)
              }}>Upgrade 2</button>

              {/* Button Upgrade 3*/}
              <button onClick={() => {
                setCurrentStep(3)

                setSpawnRate(3000)
                setSpeed(1.2)
                setGravityRadius(250)
                setGravityStrength(0.015)
              }}>Upgrade 3</button>

              <button onClick={() => {
                setCurrentStep(4)

                setSpawnRate(3500)
                setSpeed(1.6)
                setGravityRadius(350)
                setGravityStrength(0.018)
              }}>Upgrade 4</button>

              <button onClick={() => {
                setCurrentStep(5)

                setSpawnRate(2000)
                setSpeed(2)
                setGravityRadius(450)
                setGravityStrength(0.02)
              }}>Upgrade 5</button>

              <button onClick={() => {
                setCurrentStep(6)

                setSpawnRate(150)
                setSpeed(3.5)
                setGravityRadius(1550)
                setGravityStrength(0.045)
              }}>Upgrade 6</button>

              <button onClick={() => setSpawnRateLevel(prev => prev + 1)}>SpawnRateLevel {spawnRateLevel}</button>
              <button onClick={() => setGravitationLevel(prev => prev + 1)}>GravitationLevel {gravitationLevel}</button>
              <button onClick={() => setSpeedLevel(prev => prev + 1)}>SpeedLevel {speedLevel}</button>

            </div>


          </>
        )}

        {geldMode && (
          <>
            <button onClick={() => setCount((prev) => prev + 1000)} style={{ position: 'relative', top: '300px' }}>Geld</button>
          </>
        )}


        {/* Reset */}
        <div
          style={{
            position: "absolute",
            top: 12,
            left: 36,
            fontSize: 60,
            lineHeight: 1,
            color: COLORS.primary,
            cursor: "pointer",
          }}
          onClick={() => setShowConfirmation(true)} // Zeigt das Fenster an
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.0005 38.7375C36.7829 35.9673 40.0005 30.7894 40.0005 24.859C40.0005 18.1351 35.8641 12.3785 30.0005 10.0017M30.0005 33.8714V40.8824H38.0005M16 11.1448C11.2176 13.9151 8 19.0929 8 25.0233C8 31.7473 12.1368 37.5038 18.0005 39.8806M18.0005 16.011L18.0005 9L10.0005 9"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {showConfirmation && (
          <div
            style={{
              position: "absolute",
              top: "80px",
              left: "10px",
              backgroundColor: "white",
              padding: "12px",
              boxShadow: '0px 4px 4px -1px rgba(12, 12, 13, 0.10), 0px 4px 4px -1px rgba(12, 12, 13, 0.05)',
              borderRadius: "8px",
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",

            }}
          >
            <p>Neues Spiel beginnen?</p>
            <div style={{ marginTop: "12px", display: "flex", gap: "10px" }}>
              <button
                onClick={() => {
                  // Neustart-Logik
                  // removeAllSVGs();
                  // setTotalCount(0);
                  // setCount(0);
                  // setCurrentStep(0);
                  // setSpawnRateLevel(0);
                  // setSpeedLevel(0);
                  // setGravitationLevel(0);
                  // setDesinformationLevel(0);
                  // setSpeed(1);
                  // setSpawnRate(3000);
                  // setGravityStrength(0.02);
                  // setGravityRadius(100);
                  // setShowConfirmation(false); // Fenster schließen
                  window.location.reload();
                }}
                style={{
                  padding: "12px 16px",
                  backgroundColor: "white",
                  color: "black",
                  border: "black 1px solid",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <b> Neustart</b>
              </button>
              <button
                onClick={() => setShowConfirmation(false)} // Fenster schließen
                style={{
                  padding: "12px 16px",
                  backgroundColor: "#f9b066",
                  color: "black",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <b>Weiter spielen</b>
              </button>
            </div>
          </div>
        )}


        {/* Haupt Grid */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '3fr 6fr 3fr',
            gridTemplateRows: '1fr',
            gridColumnGap: '0px',
            gridRowGap: '0px',
            marginLeft: 24,
            marginRight: 24,
            marginTop: 72,
          }}
        >
          {/* --------------------------------------
          
          
                    Linke Update Spalte 


            --------------------------------------*/}
          
          <div
            style={{
              gridArea: '1 / 1 / 2 / 2',
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >

            <div
              style={{ display: 'flex', flexDirection: 'column', gap: 16, zIndex: 100 }}
            >


              {totalCount >= 1 && (
                <UpgradeComponent
                  upgrades={desinformationUpgrades}
                  currentLevel={desinformationLevel}
                  onUpgrade={() => {
                    const nextUpgrade = desinformationUpgrades.find(u => u.level === desinformationLevel + 1);
                    if (nextUpgrade && count >= nextUpgrade.cost) {
                      setDesinformationLevel(prev => prev + 1);
                      setCount(prev => prev - nextUpgrade.cost);
                      setGravityStrength(nextUpgrade.gravityStrength);
                      handleRotate();
                    }
                  }}
                  count={count}
                  labelPrefix="Desinformation"
                  upgradeType="desinformation"
                />
              )}

              {totalCount >= 7 && (
                <UpgradeComponent
                  upgrades={speedUpgrades}
                  currentLevel={speedLevel}
                  onUpgrade={() => {
                    const nextUpgrade = speedUpgrades.find(u => u.level === speedLevel + 1);
                    if (nextUpgrade && count >= nextUpgrade.cost) {
                      setSpeedLevel(prev => prev + 1);
                      setCount(prev => prev - nextUpgrade.cost);
                      setSpeed(nextUpgrade.speed);
                      handleRotate();
                    }
                  }}
                  count={count}
                  labelPrefix="Speed"
                  upgradeType="speed"
                />
              )}

              {totalCount >= 15 && (
                <UpgradeComponent
                  upgrades={gravitationUpgrades}
                  currentLevel={gravitationLevel}
                  onUpgrade={() => {
                    const nextUpgrade = gravitationUpgrades.find(u => u.level === gravitationLevel + 1);
                    if (nextUpgrade && count >= nextUpgrade.cost) {
                      setGravitationLevel(prev => prev + 1);
                      setCount(prev => prev - nextUpgrade.cost);
                      setGravityRadius(nextUpgrade.gravityRadius);
                      handleRotate();
                    }
                  }}
                  count={count}
                  labelPrefix="Gravitation"
                  upgradeType="gravitation"
                />
              )}

            </div>

          </div>
          

{/* --------------------------------------


          Main Fenster Spielfeld


  --------------------------------------*/}
          <div
            style={{
              gridArea: '1 / 2 / 2 / 3',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 32,
            }}
          >

            <div style={{ fontSize: 40, lineHeight: 1, color: COLORS.primary, display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'center', alignItems: 'center' }}>

            <h1
              style={{
                margin: 0,
                lineHeight: 1, // Kontrolliert den Leerraum zwischen den Zeilen
                textAlign: 'center', // Text mittig ausrichten (optional)
                display: 'inline-block', // Verhindert ungewollte Block-Leerzeichen
                verticalAlign: 'middle', // Verankert Text visuell
              }}
            >
              {count}
            </h1>
            <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984M12 6V18M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke={COLORS.primary}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>


            {/* <h3 style={{margin: 0}}>{totalCount}</h3> */}

            </div>


            {/* Main Fenster Spielfeld */}
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <div>
              <div className={`flex-container wireSeite ${isRotating ? "rotate" : ""}`} style={containerStyle}>
                {/* Erste Zeile (Balken) */}
                <div className="flex-item" style={productRowStyle}>
                  <div className="bar-left" style={barLeftStyle}></div>
                  <div className="bar-group-right" style={barGroupRightStyle}>
                    <div style={smallBar1Style}></div>
                    <div style={smallBar2Style}></div>
                    <div style={smallBar3Style}></div>
                  </div>
                </div>


                {speedLevel === 4 && (
                  <div className="CounterUpgrade" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, background: COLORS.pattern, justifyContent: 'center', alignItems: 'center', display: 'inline-block', borderRadius: 4 }}>
                    <div style={{ textAlign: 'center', color: 'white', fontSize: 12, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word' }}>
                      Nur noch {timer} Sekunden! Letzte Chance!
                    </div>
                  </div>
                )}


                {/* Zweite Zeile (Produktbild & Beschreibung) */}
                <div className="flex-item" style={productSectionStyle}>
                  <div className="productPicture" style={productPictureStyle}>
                    {gravitationLevel >= 3 && (
                      <div style={{ margin: 8, padding: 4, justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex', background: COLORS.pattern, borderRadius: 4 }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 10H18.764C19.1049 10 19.4401 10.0871 19.7378 10.2531C20.0355 10.4191 20.2859 10.6584 20.4651 10.9484C20.6444 11.2383 20.7465 11.5692 20.7619 11.9098C20.7773 12.2503 20.7054 12.5891 20.553 12.894L17.053 19.894C16.8869 20.2265 16.6314 20.5061 16.3152 20.7014C15.999 20.8968 15.6347 21.0002 15.263 21H11.246C11.083 21 10.92 20.98 10.761 20.94L7 20M14 10V5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0391 3.21071 12.5304 3 12 3H11.905C11.405 3 11 3.405 11 3.905C11 4.619 10.789 5.317 10.392 5.911L7 11V20M14 10H12M7 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V12C3 11.4696 3.21071 10.9609 3.58579 10.5858C3.96086 10.2107 4.46957 10 5 10H7.5"
                            stroke="white" strokeWidth="2" />
                        </svg>





                        <div style={{ width: 'auto', color: 'white', fontSize: 14, fontWeight: '500', wordWrap: 'break-word' }}>98% empfehlen es</div>
                      </div>
                    )}

                    {desinformationLevel >= 3 && (
                      <div style={{ margin: 8, padding: 4, justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex', background: COLORS.pattern, borderRadius: 4 }}>

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.9999 21.5999C8.02981 21.5999 4.7998 18.5578 4.7998 14.8186C4.7998 9.5999 11.9999 2.3999 11.9999 2.3999C11.9999 2.3999 19.1998 9.5999 19.1998 14.8186C19.1998 18.5579 15.9699 21.5999 11.9999 21.5999ZM11.9999 21.5999C10.0148 21.5999 8.3998 20.0789 8.3998 18.2093C8.3998 15.5999 11.9999 11.9999 11.9999 11.9999C11.9999 11.9999 15.5998 15.5999 15.5998 18.2093C15.5998 20.0789 13.9849 21.5999 11.9999 21.5999Z"
                            stroke="white"
                            strokeWidth="2" />
                        </svg>

                        <div style={{ width: 'auto', color: 'white', fontSize: 14, fontWeight: '500', wordWrap: 'break-word' }}>5 Tsd. verkauft</div>
                      </div>
                    )}
                  </div>
                  <div className="productDescription" style={productDescriptionStyle}>
                    {speedLevel >= 2 && speedLevel <= 2 ? (
                      <div className="CounterUpgrade" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, background: COLORS.pattern, justifyContent: 'center', alignItems: 'center', display: 'inline-block', borderRadius: 4 }}>
                        <div style={{ textAlign: 'center', color: 'white', fontSize: 12, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word' }}>Limitiert noch 16 Stunden</div>
                      </div>
                    ) : speedLevel >= 3 && speedLevel <= 3 ? (
                      <div className="CounterUpgrade" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, background: COLORS.pattern, justifyContent: 'center', alignItems: 'center', display: 'inline-block', borderRadius: 4 }}>
                        <div style={{ textAlign: 'center', color: 'white', fontSize: 12, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word' }}>Nur noch 3 Stunden! Dieses Angebot ist bald weg!</div>
                      </div>
                    ) : (
                      <div style={{ display: 'none' }}></div>
                    )}
                    {/* Titel & Preis */}
                    <div>
                      <h2 style={titleGroupStyle}>Produkt</h2>
                      {desinformationLevel >= 1 && desinformationLevel <= 3 ? (
                        <h2 style={darktitleGroupStyle}>10€ <span style={{ textDecoration: 'line-through', fontSize: 16, color: COLORS.primaryMediumOpacity }}>15€</span></h2>
                      ) : desinformationLevel >= 4 && desinformationLevel <= 4 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'flex-end' }}>
                          <h2 style={{ ...darktitleGroupStyle, display: 'flex', justifyContent: 'flex-start', gap: 16 }}>SALE <span style={{ backgroundColor: COLORS.pattern, color: 'white', paddingLeft: 4, paddingRight: 4, borderRadius: 4 }}>80%</span></h2>
                          <h2 style={darktitleGroupStyle}>10€ <span style={{ textDecoration: 'line-through', fontSize: 16, color: COLORS.primaryMediumOpacity }}>50€</span> </h2>
                        </div>
                      ) : (
                        <h2 style={titleGroupStyle}>10€</h2>
                      )}

                    </div>


                    {/* Dummy-Beschreibung */}
                    <div className="Description" style={descriptionGroupStyle}>
                      <div style={dummyText1Style}></div>
                      <div style={dummyText2Style}></div>
                      <div style={dummyText3Style}></div>
                    </div>

                    {desinformationLevel >= 2 && (
                      <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                        <div style={{ width: 24, height: 24, position: 'relative' }}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 11.6661H8L10.0404 5L14.4382 19L15.9903 11.6661H20" stroke="#D32F2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </div>
                        <div style={{ color: '#D32F2F', fontSize: 14, fontWeight: '500', wordWrap: 'break-word' }}>Nur noch 3 auf Lager</div>
                      </div>
                    )}




                    {/* Kaufen-Button */}
                    <div className='tooltip'>
                      <div style={buyButtonStyle} ref={boxRef}>Kaufen </div>
                      <span className="tooltiptext">Nur Besucher können hier kaufen</span>
                    </div>

                  </div>
                </div>
                {speedLevel >= 1 && speedLevel <= 1 ? (
                  <div className="CounterUpgrade" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4, background: COLORS.primary, justifyContent: 'center', alignItems: 'center', display: 'inline-block', borderRadius: 4 }}>
                    <div style={{ textAlign: 'center', color: 'white', fontSize: 12, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word' }}>
                      Dieses Angebot endet in 3 Tagen
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'none' }}></div>
                )}

                {/* Dritte Zeile (Kundenbewertungen) */}
                <div className="flex-item">
                  <div className="SectionLabel">
                    <h2 style={sectionLabelStyle}>Bewertungen</h2>
                  </div>


                  <div className="SectionContent" style={sectionContentStyle}>
                    {/* Beispielhafter Eintrag (wiederholt sich 3x) */}

                    {gravitationLevel >= 4 && (
                      <RatingCard
                        name="Dr. Müller"
                        fullStars={5}
                        color={COLORS.pattern}
                      />
                    )}

                    {gravitationLevel >= 1 && (
                      <RatingCard
                        name="Justus"
                        fullStars={5}
                        color={COLORS.pattern}
                      />
                    )}
                    {gravitationLevel >= 2 && (
                      <RatingCard
                        name="Tobias"
                        fullStars={5}
                        color={COLORS.pattern}
                      />
                    )}

                    {gravitationLevel >= 4 ? (
                      <></>
                    ) : (
                      <RatingCard
                        name="Jan"
                        fullStars={1}
                        color={COLORS.primary}
                      />
                    )}

                    {/* Diese SectionItem kannst du 1:1 wiederholen oder dynamisch rendern */}
                    {totalCount >= 50 ? (
                      <RatingCard
                        name="Felix"
                        fullStars={2}
                        color={COLORS.primary}
                      />
                    ) : totalCount >= 120 ? (
                      <RatingCard
                        name="Emely"
                        fullStars={1}
                        color={COLORS.primary}
                      />
                    )
                      : totalCount >= 200 ? (
                        <RatingCard
                          name="Jannik"
                          fullStars={2}
                          color={COLORS.primary}
                        />
                      ) : (
                        <></>
                      )}

                    {gravitationLevel >= 2 ? (
                      <></>
                    ) : totalCount >= 150 ? (
                      <RatingCard
                        name="Nina"
                        fullStars={1}
                        color={COLORS.primary}
                      />
                    ) : totalCount >= 75 ? (
                      <RatingCard
                        name="Dana"
                        fullStars={1}
                        color={COLORS.primary}
                      />
                    ) : (
                      <></>
                    )}



                  </div>
                </div>

                {/* Vierte Zeile (Produkt Beschreibung) */}
                <div className="flex-item">
                  <div className="SectionLabel">
                    <h2 style={sectionLabelStyle}>Produkt Beschreibung</h2>
                  </div>
                  <div className="Description" style={productDescriptionBottomStyle}>
                    <div style={dummyText1Style}></div>
                    <div style={dummyText2Style}></div>
                    <div style={dummyText3Style}></div>
                  </div>
                </div>
              </div>
            </div>
            </div>



          </div>


         
{/* --------------------------------------


          Rechte Spalte


  --------------------------------------*/}
          <div
            style={{
              gridArea: '1 / 3 / 2 / 4',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 16,
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >

            
            {/* ------------------------------------ 
        UPGRADES Info
        ------------------------------------ */}


            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                zIndex: 100,

              }}
            >
              {getLevelContent("speedLevel", speedLevel)}
              {getLevelContent("gravitationLevel", gravitationLevel)}
              {getLevelContent("desinformationLevel", desinformationLevel)}
            </div>
          </div>

        </div>


{/* --------------------------------------


          Mehr Besucher


  --------------------------------------*/}

        <div
          style={{
            position: 'absolute',
            width: '300px',
            bottom: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 100,
          }}
        >

          {totalCount >= 5 && (
            <MarketingComponent
              upgrades={spawnRateUpgrades}
              currentLevel={spawnRateLevel}
              speedLevel={speedLevel}
              gravitationLevel={gravitationLevel}
              desinformationLevel={desinformationLevel}
              onUpgrade={() => {
                const nextUpgrade = spawnRateUpgrades.find(u => u.level === spawnRateLevel + 1);

                setSpawnRateLevel(prev => prev + 1);

                setSpawnRate(nextUpgrade.spawnRate);

              }}
              count={count}
              labelPrefix="Spawn Rate"
              upgradeType="spawnRate"
            />
          )}
        </div>


      </div>
    </>
  );
}


const globalCss = `
  * {
    user-select: none;
    -webkit-user-select: none;
    margin: 0; 
    padding: 0;
    box-sizing: border-box;
    font-family: "Kanit", serif;
    
  }

  @keyframes pulse {
  0% {
    transform: scale(1);
    
  }
  50% {
    transform: scale(0.9);
    background-color: #1251a2;
  }
  100% {
    transform: scale(1);
  }
}

.box-animate {
  animation: pulse 0.3s ease-out;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tooltip-animate {
  animation: show 10s ease-in-out forwards;
}

.tooltip {
  position: relative; /* Eltern-Element wird Bezugspunkt für den Tooltip */
  display: inline-block;
}

.tooltip .tooltiptext {
  display: none;
  visibility: hidden;
  
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 8px;

  /* Position the tooltip */
  position: absolute; /* Tooltip wird außerhalb des Layouts positioniert */
  top: 115%; /* Unterhalb des Eltern-Elements */
  right: 30%; /* Horizontal zentriert */
  // transform: translateX(-50%); /* Perfekte Zentrierung */
  z-index: 10;
}

.tooltip:hover .tooltiptext {
  display: block;
  visibility: visible;
}

.level-content {
  opacity: 0;
  transform: translateX(2000px);
  transition: show 4.6s ease, transform 0.6s ease;
}

.level-content.fade-in {
  opacity: 1;
  transform: translateX(0);
}

  .wireSeite {
  transform: perspective(2500px) rotateY(7deg) translateY(5px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  animation: rotateAnimation 30s ease-in-out infinite;
  transition: transform 3s ease; /* Übergang, wenn die Animation gestoppt wird */
}

.wireSeite:hover {
  animation-play-state: paused; /* Pausiert die Animation statt sie sofort zu beenden */
  transform: perspective(3000px) rotateY(0deg) translateY(0); /* Zielposition */
}

.wireSeite.rotate {
  animation: spinAnimation 1s ease; /* Einmalige Animation */
}

@keyframes spinAnimation {
  0% {
    transform: perspective(2500px)  translateY(0px);
  }
  50% {
    transform: perspective(2500px) translateY(1000px) ;
  }
  100% {
    transform: perspective(2500px) translateY(0px);
  }
}

/* Keyframes für die Animation */
@keyframes rotateAnimation {
  0% {
    transform: perspective(2500px) rotateY(7deg) translateY(0px) ;
  }
  25% {
    transform: perspective(2500px) rotateY(-7deg) translateY(15px) ;
  }
  50% {
    transform: perspective(2500px) rotateY(7deg) translateY(0px);
  }
  75% {
    transform: perspective(2500px) rotateY(-7deg) translateY(12px);
  }
  100% {
    transform: perspective(2500px) rotateY(7deg) translateY(0px) ;
  }
}
}
`