// Modal.js
import React from 'react';

export default function Modal({ title, message, onConfirm, onCancel }) {
  return (
    <div style={modalOverlayStyle}>
      <div style={modalStyle}>
        <h2>{title}</h2>
        <p>{message}</p>
        <div style={buttonContainerStyle}>
          <button onClick={onCancel} style={cancelButtonStyle}>Nein</button>
          <button onClick={onConfirm} style={confirmButtonStyle}>Ja</button>
        </div>
      </div>
    </div>
  );
}

// Styles für das Modal
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const modalStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  maxWidth: '80%',
  textAlign: 'center',
};

const buttonContainerStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-around',
};

const confirmButtonStyle = {
  padding: '10px 20px',
  backgroundColor: 'green',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const cancelButtonStyle = {
  padding: '10px 20px',
  backgroundColor: 'red',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};
