import React, { useState, useEffect } from "react";
import LemonadeWebshop from "../clicker/LemonadeWebshop";

export default function Clicker() {
  const [currentView, setCurrentView] = useState("intro"); // "intro", "menu", "nameInput", "game"
  const [playerName, setPlayerName] = useState(""); // Name des Spielers

  // Automatischer Wechsel vom Intro zum Hauptmenü
  useEffect(() => {
    if (currentView === "intro") {
      const timer = setTimeout(() => setCurrentView("menu"), 3000); // Wechselt nach 3 Sekunden
      return () => clearTimeout(timer); // Cleanup
    }
  }, [currentView]);

  return (
    <div>
      {currentView === "intro" && <Intro />}
      {currentView === "menu" && <MainMenu setCurrentView={setCurrentView} />}
      {currentView === "nameInput" && (
        <NameInput
          playerName={playerName}
          setPlayerName={setPlayerName}
          setCurrentView={setCurrentView}
        />
      )}
      {currentView === "game" && <LemonadeWebshop playerName={playerName} />}
    </div>
  );
}

function Intro() {
  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <h1>🍋 Welcome to Lemonade Webshop! 🍋</h1>
      <p>Get ready to manage your lemonade empire...</p>
    </div>
  );
}

function MainMenu({ setCurrentView }) {
  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <h1>🍋 Lemonade Webshop 🍋</h1>
      <button
        onClick={() => setCurrentView("nameInput")}
        style={{
          fontSize: "20px",
          padding: "10px 20px",
          margin: "10px",
          cursor: "pointer",
        }}
      >
        Start Game
      </button>
      <button
        onClick={() => alert("Settings will be available soon!")}
        style={{
          fontSize: "20px",
          padding: "10px 20px",
          margin: "10px",
          cursor: "pointer",
        }}
      >
        Settings
      </button>
    </div>
  );
}

function NameInput({ playerName, setPlayerName, setCurrentView }) {
  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <h1>Enter Your Name </h1>
      <input
        type="text"
        value={playerName}
        onChange={(e) => setPlayerName(e.target.value)}
        placeholder="Your Name"
        style={{
          fontSize: "18px",
          padding: "10px",
          margin: "10px",
          textAlign: "center",
        }}
      />
      <br />
      <button
        onClick={() => {
          if (playerName.trim()) {
            setCurrentView("game");
          } else {
            alert("Please enter a valid name!");
          }
        }}
        style={{
          fontSize: "20px",
          padding: "10px 20px",
          cursor: "pointer",
        }}
      >
        Confirm
      </button>
    </div>
  );
}
