// FeedbackScreen.js
import React from 'react';

export default function FeedbackScreen({ score, fragen, onNextLevel }) {
  const prozent = (score / fragen) * 100;
  return (
    <div
      style={{
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <h1>Der Arbeitstag ist zu Ende!</h1>
      <h2>Du hast {fragen} Seite bearbeitet</h2>
      <h3>{prozent.toFixed(2)}% sind richtig</h3>
      {/* {isCorrect ? (
        <h2 style={{ color: 'green' }}>Richtige Antwort!</h2>
      ) : (
        <h2 style={{ color: 'red' }}>Falsche Antwort.</h2>
      )} */}
      <button
        onClick={onNextLevel}
        style={{
          padding: '10px 20px',
          marginTop: '20px',
          fontSize: '16px',
          cursor: 'pointer',
        }}
      >
        Zum nächsten Tag
      </button>
    </div>
  );
}
