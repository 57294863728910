// Target.js
import React, { forwardRef } from 'react'
import { RoundedBox, Html } from '@react-three/drei'
import TopBarWindow from '../Wireframe/topBarWindow'
import ShopWire from '../Wireframe/ShopWire'
import CardWire from '../Wireframe/CardWire'

const Target = forwardRef((props, ref) => {
  // Standardmäßig drei graue Boxen plus die Farben aus `props.boxColors`
  const colors = ["blue", ...props.target]
  // console.log(props)

  // Abmessungen der RoundedBox
  const boxWidth = 5
  const boxHeight = 7
  const boxDepth = 0.1

  return (
    <group ref={ref} scale={props.scale} position={[0, 0, 0]}>
      
        <group  position={[0, 0, (boxDepth + 0.1)]}>
          {/* <RoundedBox
            args={[boxWidth, boxHeight, boxDepth]}
            radius={0.1}
            smoothness={4}
          >
            <meshBasicMaterial color={colors[index]} />
          </RoundedBox> */}

          {/* Platzieren des HTML-Elements */}
          <mesh position={[0, 0, 0]}>
            {/* Plane Geometry, um die Geometrie der Vorderseite zu verwenden */}
            <planeGeometry args={[boxWidth, boxHeight]} />
            {/* Transparentes Material, damit die Plane unsichtbar ist */}
            <meshBasicMaterial 
                color={'blue'}
                transparent 
                opacity={0} 
            />

            {/* HTML-Element innerhalb des Meshes */}
            <Html
              position={[0, 0, 0.001]} // Position innerhalb des Meshes
            //   rotation={[0, 0, 0]}  // Rotation anpassen, falls erforderlich
            distanceFactor={ 1.87 }
              transform
              occlude
            >
                
              <div
                style={{
                  width: '1024px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                //   justifyContent: 'center',
                  alignItems: 'center',
                  userSelect: 'none',

                  backgroundColor: 'rgba(250, 250, 250, 1)',
                    
                  borderRadius: '8px',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  color: 'black',
                  overflow: 'hidden',
                }}
                // onPointerDown={(e) => e.stopPropagation()}
              >
                <TopBarWindow>

                </TopBarWindow>
                
                <ShopWire
                    target={props.target}
                ></ShopWire>
                {/* <CardWire
                  target={props.target}
                ></CardWire> */}
              </div>
            </Html>
          </mesh>
        </group>
      
    </group>
  )
})

export default Target
