import React, { useState } from "react";
import VisitorManager from "./VisitorManager";
import Upgrades from "./Upgrades";
import Statistics from "./Statistics";
import Countdown from "./Countdown";
import Milestones from "./Milestones";

export default function LemonadeWebshop(props) {


  const [money, setMoney] = useState(0);
  const [pricePerLemonade, setPricePerLemonade] = useState(2);
  const [purchaseChance, setPurchaseChance] = useState(0.01);
  const [visitorsPerClick, setVisitorsPerClick] = useState(1);
  const [permanentVisitors, setPermanentVisitors] = useState(0);
  const [CurrentVisitors, setCurrentVisitors] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [noPurchaseVisitors, setNoPurchaseVisitors] = useState(0);

  const [milestoneMessage, setMilestoneMessage] = useState("");

  return (
    <div>
      {/* <h1>Lemonade Webshop</h1>
      <h2>Welcome, {props.playerName}!</h2> */}

      {/* Meilensteine */}
      <Milestones totalSales={totalSales} setMilestoneMessage={setMilestoneMessage} />
      
      {/* Statistiken */}
      <Statistics
        money={money}
        totalSales={totalSales}
        totalRevenue={totalRevenue}
        totalVisitors={totalVisitors}
        totalViews={totalViews}
        noPurchaseVisitors={noPurchaseVisitors}
        milestoneMessage={milestoneMessage}
        currentVisitors={CurrentVisitors}
      />

            {/* Besucher-Manager */}
        <VisitorManager
        money={money}
        setMoney={setMoney}
        pricePerLemonade={pricePerLemonade}
        purchaseChance={purchaseChance}
        visitorsPerClick={visitorsPerClick}
        setTotalVisitors={setTotalVisitors}
        setCurrentVisitors={setCurrentVisitors}
        totalSales={totalSales}
        setTotalSales={setTotalSales}
        totalRevenue={totalRevenue}
        setTotalRevenue={setTotalRevenue}
        totalViews={totalViews}
        setTotalViews={setTotalViews}
        noPurchaseVisitors={noPurchaseVisitors}
        setNoPurchaseVisitors={setNoPurchaseVisitors}
        permanentVisitors={permanentVisitors}
      />

      {/* Upgrades */}
      <Upgrades
        money={money}
        setMoney={setMoney}
        purchaseChance={purchaseChance}
        setPurchaseChance={setPurchaseChance}
        visitorsPerClick={visitorsPerClick}
        setVisitorsPerClick={setVisitorsPerClick}
        permanentVisitors={permanentVisitors}
        setPermanentVisitors={setPermanentVisitors}
        totalSales={totalSales}
      />
      
      {/* Countdown */}
      <Countdown purchaseChance={purchaseChance} setPurchaseChance={setPurchaseChance} />
    </div>
  );
}
