// App.jsx
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


import Home from './components/pages/Home'
import Game from './components/pages/Game'
import Clicker from './components/pages/Clicker'
import Builder from './components/pages/Builder.jsx'
import FlexTest from './components/pages/FlexTest.jsx'
import Start from './components/pages/Start.jsx'

export default function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/game" element={<Game />} />
        <Route path="/clicker" element={<Clicker />} />
        <Route path='/builder' element={<Builder />} />
        <Route path='/' element={<Start />} />
      </Routes>
    </Router>
  )
}
