import React, { useEffect, useRef, useState } from "react";

export default function Milestones({ totalSales, setMilestoneMessage }) {
  // Ref für Meilensteine
  const milestones = useRef([10, 100, 1000, 10000]); // Liste der Meilensteine
  const [currentMilestone, setCurrentMilestone] = useState(10); // Nächster Meilenstein
  const [previousMilestone, setPreviousMilestone] = useState(0); // Vorheriger Meilenstein
  const [progress, setProgress] = useState(0); // Fortschritt in %

  // Überprüfe Meilensteine und Fortschritt
  useEffect(() => {
    // Check for milestone achievement
    if (milestones.current.includes(totalSales)) {
      setMilestoneMessage(`🎉 Congratulations! You've reached ${totalSales} sales!`);
      milestones.current = milestones.current.filter((m) => m > totalSales); // Entferne erreichte Meilensteine
    }

    // Setze den nächsten und vorherigen Meilenstein
    const nextMilestone = milestones.current[0] || currentMilestone;
    setCurrentMilestone(nextMilestone);
    setPreviousMilestone(totalSales < currentMilestone ? previousMilestone : currentMilestone);

    // Fortschritt berechnen
    const progressPercentage =
      ((totalSales - previousMilestone) / (nextMilestone - previousMilestone)) * 100;
    setProgress(Math.min(Math.max(progressPercentage, 0), 100)); // Begrenze auf 0-100%
  }, [totalSales, setMilestoneMessage, currentMilestone, previousMilestone]);

  return (
    <div>
      <p>
        Sales: {totalSales}/{currentMilestone} (Next milestone: {currentMilestone})
      </p>
      <div
        style={{
          position: "relative",
          height: "20px",
          width: "100%",
          backgroundColor: "#ddd",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: "100%",
            width: `${progress}%`,
            backgroundColor: "#4caf50",
            transition: "width 0.3s ease",
          }}
        ></div>
      </div>
    </div>
  );
}
