import React, { useState, useEffect } from "react";

export default function Countdown(props) {
  const [timeLeft, setTimeLeft] = useState(1 * 60 * 60); // 12 Stunden in Sekunden

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    return () => clearInterval(timer); // Cleanup beim Unmount
  }, []);

  // Umrechnung in Stunden, Minuten und Sekunden
  const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <>
      <div
        style={{
          border: "4px solid red",
          textAlign: "center",
          color: "red",
          fontWeight: "600",
          display: props.target === "Countdown" ? "block" : "none",
        }}
      >
        <span>{formatTime(timeLeft)}</span>
      </div>
    </>
  );
}
