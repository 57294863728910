// texts.js

const texts = {
    nav: {
      title: "Dark Patterns",
      gameButton: "Spiel",
    },
    sections: [
      {
        id: "comparisonPrevention",
        title: "Comparison Prevention",
        shortDescription: "Vergleich absichtlich erschwert, um unüberlegte Käufe zu fördern",
        content: "Kaufentscheidung: Wenn jemand etwas kaufen will, vergleicht er oft den Preis, die Funktionen und seine eigenen Bedürfnisse. Vergleichsprobleme: Wenn es zu schwer wird, diese Vergleiche anzustellen, kann die Person frustriert aufgeben und schnell eine Entscheidung treffen. Täuschungsmuster: Manche Verkäufer machen den Vergleich absichtlich schwer, damit die Käufer nicht gut nachdenken können. Kognitive Verzerrungen: Wenn es schwer ist zu vergleichen, ist die Person leichter zu beeinflussen. Ziel der Verkäufer: Verkäufer tun das, um mehr Geld zu verdienen, auch wenn es nicht das Beste für den Käufer ist.",
        
      },
      {
        id: "confirmshaming",
        title: "Confirmshaming",
        shortDescription: "Schuldgefühle nutzen, um Zustimmung zu erzwingen",
        content: "Diese Methode nutzt unangenehme Gefühle wie Schuld oder Scham, um die Entscheidungen der Nutzer zu beeinflussen. Websites oder Apps verwenden Schaltflächen, die negativ formuliert sind, um Nutzer schlecht fühlen zu lassen, wenn sie ablehnen. Ziel der Methode: Confirmshaming soll die Nutzer dazu bringen, etwas zu tun, was dem Anbieter nützt.",
        
      },
      {
        id: "disguisedAds",
        title: "Disguised Ads",
        shortDescription: "Werbung als echten Inhalt tarnen, um Klicks zu erhöhen",
        content: "Diese Methode verwischt absichtlich die Grenze zwischen echtem Inhalt und Werbung, um Nutzer zu verwirren. Diese Anzeigen sehen oft wie Teile der Website aus, z.B. wie Bedienelemente oder verwandte Artikel, um Nutzer dazu zu bringen, darauf zu klicken. Ziel der Methode: Website-Betreiber verdienen mehr Geld durch mehr Anzeigenansichten.",
      },
      {
        id: "fakeScarcity",
        title: "Fake Scarcity",
        shortDescription: "Falsche Knappheit, um schnelle Kaufentscheidungen zu erzwingen",
        content: "Diese Methode erzeugt ein falsches Gefühl von begrenzter Verfügbarkeit eines Produkts oder einer Dienstleistung. Nutzer sollen schnell handeln aus Angst, etwas zu verpassen. Falsche Nachrichten über niedrige Lagerbestände oder hohe Nachfrage sind typisch.",
      },
      {
        id: "fakeSocialProof",
        title: "Fake Social Proof",
        shortDescription: "Gefälschte Empfehlungen, um Beliebtheit vorzutäuschen",
        content: "Diese Methode erzeugt eine falsche Illusion von Beliebtheit und Glaubwürdigkeit. Es werden gefälschte oder übertriebene Empfehlungen gezeigt. Ziel der Methode: Anbieter wollen Nutzer dazu bringen, etwas zu kaufen.",
      },
      {
        id: "fakeUrgency",
        title: "Fake Urgency",
        shortDescription: "Zeitdruck erzeugen, um unüberlegte Entscheidungen zu fördern",
        content: "Wenn Nutzer unter Zeitdruck stehen, können sie Informationen nicht mehr kritisch bewerten. Anbieter nutzen dies aus, um Nutzer zu schnellen Handlungen zu drängen.",
      },
      {
        id: "forcedAction",
        title: "Forced Action",
        shortDescription: "Erzwungene Handlungen für ein gewünschtes Ergebnis",
        content: "Anbieter bieten Nutzern etwas an, das sie wollen, aber verlangen im Gegenzug eine Handlung. Manchmal geschieht dies unauffällig oder durch trickreiche Formulierungen, um die Handlung attraktiver erscheinen zu lassen.",
      },
      {
        id: "hardToCancel",
        title: "Hard to Cancel",
        shortDescription: "Anmeldung einfach, Kündigung schwer und kompliziert",
        content: "Es ist einfach, sich für einen Dienst anzumelden, aber schwer, ihn zu kündigen. Die Kündigungsoption wird oft versteckt oder der Prozess ist kompliziert und zeitaufwendig.",
        buttonText: "Weiter",
      },
      {
        id: "hiddenCosts",
        title: "Hidden Costs",
        shortDescription: "Zusätzliche Kosten werden versteckt angezeigt",
        content: "Versteckte Kosten bedeuten, dass zusätzliche Gebühren oder Kosten beim Kauf oder bei der Anmeldung zuerst verborgen werden. Nutzer sind dann eher bereit, trotz der unerwarteten Kosten fortzufahren.",
        buttonText: "Weiter",
      },
      {
        id: "hiddenSubscription",
        title: "Hidden Subscription",
        shortDescription: "Abo versteckt sich hinter vermeintlich einmaligen Käufen",
        content: "Nutzer denken, sie kaufen einmalig etwas, merken aber nicht, dass sie sich für ein wiederkehrendes Abonnement anmelden. Oft ist es schwer, das Abonnement zu kündigen.",
        buttonText: "Weiter",
      },
      {
        id: "nagging",
        title: "Nagging",
        shortDescription: "Ständige Unterbrechungen, um Zustimmung zu erzwingen",
        content: "Jedes Mal, wenn eine App oder Website den Nutzer unterbricht, kostet das Zeit und Aufmerksamkeit. Die Belastung summiert sich, bis es für den Nutzer einfacher ist, nachzugeben, auch wenn es nicht in seinem besten Interesse ist.",
        buttonText: "Weiter",
      },
      {
        id: "obstruction",
        title: "Obstruction",
        shortDescription: "Hindernisse schaffen, um Aufgaben zu erschweren",
        content: "Obstruction schafft absichtlich Hindernisse, um Nutzer davon abzuhalten, eine Aufgabe zu erledigen oder eine Aktion auszuführen. Ziel ist es, die Nutzer zu erschöpfen, damit sie aufgeben.",
        buttonText: "Weiter",
      },
      {
        id: "preselection",
        title: "Preselection",
        shortDescription: "Voreingestellte Optionen, um Nutzer zu beeinflussen",
        content: "Preselection nutzt den \"Default-Effekt\" aus, bei dem Menschen tendieren, die voreingestellte Option zu wählen. Anbieter nutzen dies, um Verbraucher zu beeinflussen, z.B. durch vorangekreuzte Checkboxen.",
        buttonText: "Weiter",
      },
      {
        id: "sneaking",
        title: "Sneaking",
        shortDescription: "Wichtige Informationen verstecken, um Zustimmung zu bekommen",
        content: "Beim \"Sneaking\" wird absichtlich wichtige Informationen für den Nutzer versteckt oder verschleiert, um ihn zu einer gewünschten Handlung zu verleiten.",
        buttonText: "Weiter",
      },
      {
        id: "trickWording",
        title: "Trick Wording",
        shortDescription: "Mehrdeutige Sprache nutzen, um Nutzer zu täuschen",
        content: "Trickreiche Formulierungen nutzen die Erwartungen der Nutzer und mehrdeutige Sprache, um sie zu täuschen. Nutzer überfliegen oft Texte und könnten so die wahre Bedeutung nicht bemerken.",
        buttonText: "Weiter",
      },
      {
        id: "visualInterference",
        title: "Visual Interference",
        shortDescription: "Visuelles Design manipulieren, um Infos zu verstecken",
        content: "Visuelles Design kann manipuliert werden, um Informationen zu verstecken oder zu verschleiern. Dies geschieht oft durch schlechte Lesbarkeit oder chaotische Oberflächen.",
        buttonText: "Weiter",
      }
    ],
    footer: {
      content: "© 2024 Dark Patterns Projekt",
    },
    buttons: {
      gameButton: { label: "Start Game", link: "/game", type:"buttonPrimary" },
      homeButton: { label: "Zum Start", link: "/", type:"buttonPrimary"},
      infoButton: { label: "Mehr infos zu dem", link: "/game", type:"secundaryButton" },
    },
    game: {
      story: {
        level1: {
          name: "Tag 1 - Montag",
          
          task: "Countdown",
          
          answeres: [
            { id: "nr1", label: "Social Proof" },
            { id: "nr2", label: "Countdown" },
            { id: "nr3", label: "Scarcity" },
            { id: "nr4", label: "Roach Motel" }
          ],
          solution: "nr3",
          talk: [
            { id: "1", player: "Boss", delay: 1, text: "Willkommen!" },
            { id: "2", player: "Boss", delay: 2, text: "Lass uns dein Wissen über Dark Patterns testen" },
            { id: "3", player: "Boss", delay: 3, text: "Weißt du, welches Muster Schuldgefühle einsetzt, um Nutzer zu beeinflussen?" },
            { id: "4", player: "Boss", delay: 5, text: "Ein kleiner Tipp: Es enthält das Wort 'shaming'." },
            { id: "5", player: "Boss", delay: 8, text: "Also, was denkst du?" }
          ]
        },
        // level2: {
        //   name: "Tag 2 - Di",
          
        //   question: "Welches Dark Pattern fügt dem Warenkorb unbemerkt zusätzliche Artikel hinzu?",
        //   answeres: [
        //     { id: "nr1", label: "Sneak into Basket" },
        //     { id: "nr2", label: "Forced Continuity" },
        //     { id: "nr3", label: "Hidden Costs" },
        //     { id: "nr4", label: "Disguised Ads" }
        //   ],
        //   solution: "nr1",
        //   talk: [
        //     { id: "1", player: "Boss", delay: 1, text: "Gut gemacht! Weiter zur nächsten Frage." },
        //     { id: "2", player: "Boss", delay: 2, text: "Kennst du das Muster, bei dem Artikel heimlich in den Warenkorb gelegt werden?" },
        //     { id: "3", player: "Boss", delay: 5, text: "Es hat etwas mit 'sneaky' zu tun." }
        //   ]
        // },
        // level3: {
        //   name: "Tag 3 - Mi",
          
        //   question: "Welches Dark Pattern erschwert absichtlich den Abmeldeprozess?",
        //   answeres: [
        //     { id: "nr1", label: "Roach Motel" },
        //     { id: "nr2", label: "Bait and Switch" },
        //     { id: "nr3", label: "Misdirection" },
        //     { id: "nr4", label: "Preselection" }
        //   ],
        //   solution: "nr1",
        //   talk: [
        //     { id: "1", player: "Boss", delay: 1, text: "Nicht schlecht! Lass uns weitermachen." },
        //     { id: "2", player: "Boss", delay: 2, text: "Welches Muster macht es schwierig, sich abzumelden?" },
        //     { id: "3", player: "Boss", delay: 4, text: "Denk an ein Motel, in das man leicht reinkommt, aber schwer wieder raus." }
        //   ]
        // },
        // level4: {
        //   name: "Tag 4 - Do",
          
        //   question: "Welches Dark Pattern präsentiert Werbung als normalen Inhalt?",
        //   answeres: [
        //     { id: "nr1", label: "Disguised Ads" },
        //     { id: "nr2", label: "Forced Enrollment" },
        //     { id: "nr3", label: "Hidden Information" },
        //     { id: "nr4", label: "Social Proof" }
        //   ],
        //   solution: "nr1",
        //   talk: [
        //     { id: "1", player: "Boss", delay: 1, text: "Du machst Fortschritte!" },
        //     { id: "2", player: "Boss", delay: 3, text: "Weißt du, welches Muster Werbung als normalen Inhalt tarnt?" },
        //     { id: "3", player: "Boss", delay: 5, text: "Es hat mit 'verkleideter' Werbung zu tun." }
        //   ]
        // },
        // level5: {
        //   name: "Tag 5 - Fr",
          
        //   question: "Welches Dark Pattern nutzt künstliche Verknappung, um Druck aufzubauen?",
        //   answeres: [
        //     { id: "nr1", label: "Scarcity" },
        //     { id: "nr2", label: "Nagging" },
        //     { id: "nr3", label: "Trick Questions" },
        //     { id: "nr4", label: "Preselection" }
        //   ],
        //   solution: "nr1",
        //   talk: [
        //     { id: "1", player: "Boss", delay: 1, text: "Fast geschafft! Letzte Frage." },
        //     { id: "2", player: "Boss", delay: 2, text: "Welches Muster erzeugt künstliche Knappheit, um Druck aufzubauen?" },
        //     { id: "3", player: "Boss", delay: 4, text: "Denk an das englische Wort für 'Knappheit'." }
        //   ]
        // }
      }
    }
    
    
};
  
  export default texts;