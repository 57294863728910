export default function Scarcity(props) {

    return <>
    <div
        style={{
          
          textAlign: "left",
          color: "red",
          fontWeight: "600",
          display: props.target === "Scarcity" ? "block" : "none",
          marginTop: '12px',
        }}
      >
        <span><b>Nur noch 1 Produkt Verfügbar</b></span>
      </div>
    </>
}