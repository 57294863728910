import { useState, useEffect, useRef } from 'react';

// -----------------------------------------------------------------------------
//   COMPONENT: Builder
// -----------------------------------------------------------------------------
export default function Builder() {
  // ---------------------------------------------------------------------------
  //   REACT STATES & REFS
  // ---------------------------------------------------------------------------
  const [count, setCount] = useState(0);         // Kollisionen / Treffer in Box
  const [speed, setSpeed] = useState(1);         // Grundgeschwindigkeit
  const [spawn, setSpawn] = useState(0.8);       // Wahrscheinlichkeit für neuen Cursor
  const [imgSrc, setImgSrc] = useState('../pictures/Page.png');
  
  // Wird eine der drei Boxen bereits angewendet?
  // (Optional: Wenn du unterschiedliche States willst, 
  //  kannst du z.B. dragApplied1, dragApplied2, ... anlegen.)
  const [dragApplied, setDragApplied] = useState(false);

  // Gravitationseinstellungen
  const [gravityRadius, setGravityRadius] = useState(100);
  const [gravityStrength, setGravityStrength] = useState(18);

  const boxRef = useRef(null);

  // ---------------------------------------------------------------------------
  //   SPAWN & ANIMATION
  // ---------------------------------------------------------------------------
  function spawnSVG() {
    const svgDiv = document.createElement('div');
    svgDiv.className = 'svg-icon';
    svgDiv.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M4 0l16 12.279-6.951 1.17 
                 4.325 8.817-3.596 1.734-4.35-8.879
                 -5.428 4.702z"/>
      </svg>
    `;
  
    let x = Math.random() * (window.innerWidth - 50);
    let y = Math.random() * (window.innerHeight - 50);
    svgDiv.style.left = `${x}px`;
    svgDiv.style.top = `${y}px`;
    document.body.appendChild(svgDiv);
  
    // Zufällige Startbewegung
    let dx = Math.random() < 0.5 ? -1 : 1;
    let dy = Math.random() < 0.5 ? -1 : 1;
  
    // Timer für automatisches Entfernen nach 1 min:
    const removeTimer = setTimeout(() => {
      if (svgDiv.isConnected) {
        svgDiv.remove();
      }
    }, 60000); // 60 Sekunden
  
    function moveSVG() {
      // Aktuelle Werte
      const currentRadius = gravityRadius;
      const currentStrength = gravityStrength;
  
      // Schlängeln
      let angle = Math.atan2(dy, dx);
      angle += (Math.random() - 0.5) * 1.14;
      dx = Math.cos(angle);
      dy = Math.sin(angle);
  
      // Bewegung
      x += dx * speed;
      y += dy * speed;
  
      // Gravitation
      const boxEl = boxRef.current;
      if (boxEl) {
        const boxRect = boxEl.getBoundingClientRect();
        const cx = boxRect.left + boxRect.width / 2;
        const cy = boxRect.top + boxRect.height / 2;
  
        let gx = cx - x;
        let gy = cy - y;
        const dist = Math.sqrt(gx * gx + gy * gy);
  
        if (dist < currentRadius) {
          const force = currentStrength * (1 - dist / currentRadius);
          gx /= dist;
          gy /= dist;
          dx += gx * force;
          dy += gy * force;
        }
      }
  
      // Bounce am Rand
      if (x <= 0 || x >= window.innerWidth - 50) {
        dx *= -1;
        x += dx * speed;
      }
      if (y <= 0 || y >= window.innerHeight - 50) {
        dy *= -1;
        y += dy * speed;
      }
  
      svgDiv.style.left = `${x}px`;
      svgDiv.style.top = `${y}px`;
  
      // Kollision mit Box?
      if (boxEl) {
        const boxRect = boxEl.getBoundingClientRect();
        const svgRect = svgDiv.getBoundingClientRect();
        if (
          svgRect.left < boxRect.right &&
          svgRect.right > boxRect.left &&
          svgRect.top < boxRect.bottom &&
          svgRect.bottom > boxRect.top
        ) {
          clearTimeout(removeTimer);
          svgDiv.remove();
          setCount((prev) => prev + 1);
          return;
        }
      }
  
      if (!svgDiv.isConnected) {
        clearTimeout(removeTimer);
        return; 
      }
  
      requestAnimationFrame(moveSVG);
    }
  
    moveSVG();
  }

  // Intervall, um alle X ms neue Cursors zu spawnen
  useEffect(() => {
    let intervalId;
    if (speed) {
      intervalId = setInterval(() => {
        if (Math.random() >= spawn) spawnSVG();
      }, 100 / speed);
    }
    return () => clearInterval(intervalId);
  }, [speed, spawn]);

  // ---------------------------------------------------------------------------
  //  DRAG & DROP
  // ---------------------------------------------------------------------------
  // => Box 1
  function handleDragStartBox1(e) {
    e.dataTransfer.setData('text/plain', 'blueDiv1');
  }
  // => Box 2
  function handleDragStartBox2(e) {
    e.dataTransfer.setData('text/plain', 'blueDiv2');
  }
  // => Box 3
  function handleDragStartBox3(e) {
    e.dataTransfer.setData('text/plain', 'blueDiv3');
  }

  // => Drop auf das Bild
  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e) {
    e.preventDefault();
    const data = e.dataTransfer.getData('text/plain');

    // Herausfinden, welche Box gezogen wurde
    if (data === 'blueDiv1') {
      // Box 1 => Bild zu Page_u1.png
      setImgSrc('../pictures/Page_u1.png');
      setDragApplied(true);
      // Du kannst hier auch States anpassen (z. B. speed, gravity, etc.)
      setSpeed(2);
      setGravityStrength(0.1);

    } else if (data === 'blueDiv2') {
      // Box 2 => Bild zu Page_u2.png
      setImgSrc('../pictures/Page_u2.png');
      // Ggf. andere States verändern
      setGravityStrength(0.1);
      setGravityRadius(800);
      
    } else if (data === 'blueDiv3') {
      // Box 3 => Bild zu Page_u3.png
      setImgSrc('../pictures/Page_u3.png');
      // Ggf. andere States verändern
      setGravityStrength(1);
      setGravityRadius(1200);
    }
  }

  // ---------------------------------------------------------------------------
  //  STATUSTEXT (Nur für Box 1; du kannst Ähnliches für Box 2/3 machen)
  // ---------------------------------------------------------------------------
  let statusText1;
  if (count < 25) {
    statusText1 = 'Verfügbar ab 25';
  } else {
    statusText1 = dragApplied ? 'Angewendet' : 'Bereit';
  }

  // Dasselbe Prinzip für Box 2
  let statusText2;
  if (count < 100) {
    statusText2 = 'Verfügbar ab 100';
  } else {
    statusText2 = 'Bereit'; // (Oder "Angewendet", wenn du willst)
  }

  // Dasselbe Prinzip für Box 3
  let statusText3;
  if (count < 1000) {
    statusText3 = 'Verfügbar ab 1000';
  } else {
    statusText3 = 'Bereit'; // (Oder "Angewendet", wenn du willst)
  }

  // ---------------------------------------------------------------------------
  //  RENDER
  // ---------------------------------------------------------------------------
  return (
    <div style={styles.wrapper}>
      <style>{globalCss}</style>

      {/* Counter */}
      <div className="counter">{count}</div>

      {/* Spawn-Button */}
      <button onClick={spawnSVG} style={styles.spawnButton}>Spawn SVG</button>

      {/* Speed-Regler */}
      <div style={styles.sliderContainer}>
        <p>Speed</p>
        <input
          type="range"
          min="1"
          max="4"
          value={speed}
          onChange={(e) => setSpeed(Number(e.target.value))}
        />
      </div>

      {/* Grav-Parameter */}
      <div style={styles.gravitySliders}>
        <p>Gravitationsradius: {gravityRadius}px</p>
        <input
          type="range"
          min="50"
          max="1600"
          step="10"
          value={gravityRadius}
          onChange={(e) => setGravityRadius(Number(e.target.value))}
        />
        <p>Gravitationsstärke: {gravityStrength.toFixed(2)}</p>
        <input
          type="range"
          min="0.00"
          max="80.0"
          step="0.01"
          value={gravityStrength}
          onChange={(e) => setGravityStrength(Number(e.target.value))}
        />
      </div>

      {/* <div
  style={{
    position: 'absolute',
    border: '2px dashed red',
    borderRadius: '50%',
    pointerEvents: 'none',
    width: 2 * gravityRadius,
    height: 2 * gravityRadius,
    left: `calc(50% - ${gravityRadius}px)`,
    top: `calc(50% - ${gravityRadius}px)`,
    zIndex: 999,
  }}
/> */}

      {/* Box als Zentrum */}
      <div className="box" ref={boxRef} style={styles.boxPosition} />

      {/* Bild (Dropzone) */}
      <img
        src={imgSrc}
        style={styles.centeredImage}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        alt="Dropzone"
      />

      {/* 
        Drei Boxen (Box1, Box2, Box3).
        - Box1: aktiv ab count >= 25
        - Box2: aktiv ab count >= 100
        - Box3: aktiv ab count >= 1000 
      */}
      <div style={styles.blueBoxContainer}>

        {/* ----- BOX 1 ----- */}
        <div
          style={{
            ...styles.blueBox,
            // Nur draggable, wenn count >= 25
            cursor: count >= 25 ? 'pointer' : 'not-allowed',
            opacity: count >= 25 ? 1 : 0.5,
          }}
          draggable={count >= 25}
          onDragStart={handleDragStartBox1}
        >
          <div style={styles.blueBoxIcon}>
            <div style={styles.blueBoxIconInner} />
          </div>
          <div style={styles.blueBoxTitle}>Upgrade 1</div>
          <div
            style={{
              ...styles.blueBoxStatus,
              color: count >= 25 ? '#009951' : 'rgb(187, 26, 109)',
            }}
          >
            {statusText1}
          </div>
        </div>

        {/* ----- BOX 2 ----- */}
        <div
          style={{
            ...styles.blueBox,
            cursor: count >= 100 ? 'pointer' : 'not-allowed',
            opacity: count >= 100 ? 1 : 0.5,
          }}
          draggable={count >= 100}
          onDragStart={handleDragStartBox2}
        >
          <div style={styles.blueBoxIcon}>
            <div style={styles.blueBoxIconInner} />
          </div>
          <div style={styles.blueBoxTitle}>Upgrade 2</div>
          <div
            style={{
              ...styles.blueBoxStatus,
              color: count >= 100 ? '#009951' : 'rgb(187, 26, 109)',
            }}
          >
            {statusText2}
          </div>
        </div>

        {/* ----- BOX 3 ----- */}
        <div
          style={{
            ...styles.blueBox,
            cursor: count >= 1000 ? 'pointer' : 'not-allowed',
            opacity: count >= 1000 ? 1 : 0.5,
          }}
          draggable={count >= 1000}
          onDragStart={handleDragStartBox3}
        >
          <div style={styles.blueBoxIcon}>
            <div style={styles.blueBoxIconInner} />
          </div>
          <div style={styles.blueBoxTitle}>Upgrade 3</div>
          <div
            style={{
              ...styles.blueBoxStatus,
              color: count >= 1000 ? '#009951' : 'rgb(187, 26, 109)',
            }}
          >
            {statusText3}
          </div>
        </div>

      </div>
    </div>
  );
}

// -----------------------------------------------------------------------------

const styles = {
  wrapper: {
    margin: 0,
    padding: 0,
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
  spawnButton: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  sliderContainer: {
    position: 'absolute',
    top: 60,
    left: 10,
  },
  gravitySliders: {
    position: 'absolute',
    top: 120,
    left: 10,
  },
  boxPosition: {
    position: 'absolute',
    top: '45%',
    left: '56%',
    transform: 'translate(-50%, -50%)',
  },
  centeredImage: {
    position: 'absolute',
    width: '500px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  blueBoxContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    gap: 40,
  },
  blueBox: {
    width: 'auto',
    height: 'auto',
    padding: 12,
    borderRadius: 16,
    border: '1px #90A4AE solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    display: 'inline-flex',
    background: 'white',
    color: 'black',
    fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 1,
    wordWrap: 'break-word',
  },
  blueBoxIcon: {
    width: 12,
    height: 24,
    position: 'relative',
  },
  blueBoxIconInner: {
    width: 20,
    height: 18,
    left: 2,
    top: 3,
    position: 'absolute',
    border: '2px black solid',
  },
  blueBoxTitle: {
    textAlign: 'center',
    color: 'black',
    fontSize: 22,
    fontFamily: 'Arial',
    fontWeight: '700',
    lineHeight: 1,
    wordWrap: 'break-word',
  },
  blueBoxStatus: {
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Arial',
    fontWeight: '700',
    lineHeight: 2,
    wordWrap: 'break-word',
  },
};

const globalCss = `
  * {
    user-select: none;
    -webkit-user-select: none;
    margin: 0; 
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
  }

  .box {
    width: 200px;
    height: 40px;
  }

  .svg-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    pointer-events: none;
  }

  .counter {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 160px;
    font-weight: 600;
    word-wrap: break-word;
  }



`;