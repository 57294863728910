import anime from 'animejs/lib/anime.es.js';
import React, { useEffect, useState } from 'react';

export default function StartScreen({ onStart }) {
const [showButton, setShowButton] = useState(false); // State für die Button-Anzeige

useEffect(() => {
  const messagesEl = document.querySelector('.messages');
  const typingSpeed = 20;
  const loadingText = '<b>•</b><b>•</b><b>•</b>';
  let messageIndex = 0;

  const messages = [
    'Hey👋',
    'Willkommen bei Firma X!',
    'Wir suchen neue Mitarbeiter',
    'Wenn du die Woche überstehst, kannst du hier arbeiten',
    'Dein Ziel ist es, so viele Webseiten wie möglich zu optimieren',
    'Klicke auf den Button unten, um loszulegen',
  ];  
  

  const createBubbleElements = (message) => {
    const bubbleEl = document.createElement('div');
    const messageEl = document.createElement('span');
    const loadingEl = document.createElement('span');
    bubbleEl.classList.add('bubble', 'is-loading', 'left');
    messageEl.classList.add('message');
    loadingEl.classList.add('loading');
    messageEl.innerHTML = message;
    loadingEl.innerHTML = loadingText;
    bubbleEl.appendChild(loadingEl);
    bubbleEl.appendChild(messageEl);
    return {
      bubble: bubbleEl,
      message: messageEl,
      loading: loadingEl,
    };
  };

  const sendMessage = (message) => {
    const loadingDuration =
      message.replace(/<(?:.|\n)*?>/gm, '').length * typingSpeed + 500;
    const elements = createBubbleElements(message);
    messagesEl.appendChild(elements.bubble);
    messagesEl.appendChild(document.createElement('br'));

    elements.message.style.display = 'none';
    anime({
      targets: elements.bubble,
      duration: 800,
      easing: 'easeOutElastic',
    });

    setTimeout(() => {
      anime({
        targets: elements.loading.querySelectorAll('b'),
        scale: [1, 1.5],
        opacity: [0.5, 1],
        duration: 300,
        loop: true,
        direction: 'alternate',
        delay: (el, i) => i * 100,
      });
    }, 100);

    setTimeout(() => {
      anime({
        targets: elements.message,
        opacity: [0, 1],
        duration: 500,
        easing: 'easeInOutQuad',
        begin: () => {
          elements.loading.style.display = 'none';
          elements.bubble.classList.remove('is-loading');
          elements.message.style.display = 'block';
        },
        complete: () => {
          if (messageIndex === messages.length) {
            // Button anzeigen, wenn alle Nachrichten durch sind
           
          }
        },
      });
    }, loadingDuration);
  };

  const sendMessages = () => {
    if (messageIndex < messages.length) {
      sendMessage(messages[messageIndex]);
      messageIndex++;
      setTimeout(sendMessages, 2000);
    }
    setShowButton(true);
  };

  sendMessages();
}, []);

return (
  <div className="start-screen">
    <div className="messages"></div>
    {showButton && (
      <button onClick={onStart} style={{ marginTop: '20px' }}>
        Start
      </button>
    )}
  </div>
)
}