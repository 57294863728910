import React, { useEffect, useState } from "react";

export default function Upgrades({
  money,
  setMoney,
  purchaseChance,
  setPurchaseChance,
  visitorsPerClick,
  setVisitorsPerClick,
  permanentVisitors,
  setPermanentVisitors,
  tota
}) {
  // Dynamische Upgrade-Kosten
  
  const [purchaseChanceUpgradeCost, setPurchaseChanceUpgradeCost] = useState(15);
  const [visitorUpgradeCost, setVisitorUpgradeCost] = useState(10)
  const [visitorTimerUpgradeCost, setVisitorTimerUpgradeCost] = useState(20)
  const [permanentVisitorUpgradeCost, setPermanentVisitorUpgradeCost] = useState(50)
  

  const upgradePurchaseChance = () => {
    if (money >= purchaseChanceUpgradeCost) {
      setMoney((prev) => prev - purchaseChanceUpgradeCost);
      setPurchaseChance((prev) => Math.min(prev + 0.005, 0.9))
      setPurchaseChanceUpgradeCost((prev) => prev * 1.5)
    }
  };

  const upgradeVisitorButton = () => {
    if (money >= visitorUpgradeCost) {
      setMoney((prev) => prev - visitorUpgradeCost);
      setVisitorsPerClick((prev) => prev + 1)
      setVisitorUpgradeCost((prev) => prev * 1.5)
    }
  };

  const upgradePermanentVisitors = () => {
    if (money >= permanentVisitorUpgradeCost) {
      setMoney((prev) => prev - permanentVisitorUpgradeCost);
      setPermanentVisitors((prev) => prev + 1);
    }
    console.log(permanentVisitors)
  };

  const upgradeVisitorTimer =  () => {
    if (money >= visitorTimerUpgradeCost) {
      setMoney((prev) => prev - visitorTimerUpgradeCost);
      setVisitorTimer((prev) => prev * 0.9)
    }
  }
 
  return (
    <div>
      <button
        onClick={upgradePurchaseChance}
        disabled={money < purchaseChanceUpgradeCost}
        style={{
          fontSize: "16px",
          padding: "10px 15px",
          margin: "10px",
          cursor: money >= purchaseChanceUpgradeCost ? "pointer" : "not-allowed",
        }}
      >
        Verbessert die Conversion Rate (+0.5%) | {purchaseChanceUpgradeCost}
      </button>
      <button
        onClick={upgradeVisitorButton}
        disabled={money < visitorUpgradeCost}
        style={{
          fontSize: "16px",
          padding: "10px 15px",
          margin: "10px",
          cursor: money >= visitorUpgradeCost ? "pointer" : "not-allowed",
        }}
      >
        Upgrade Besucher pro Klick (+1) | {visitorUpgradeCost}
      </button>
      <button
        onClick={upgradePermanentVisitors}
        disabled={money < permanentVisitorUpgradeCost}
        style={{
          fontSize: "16px",
          padding: "10px 15px",
          margin: "10px",
          cursor: money >= permanentVisitorUpgradeCost ? "pointer" : "not-allowed",
        }}
      >
        Mehr permanente Besucher (+1) | {permanentVisitorUpgradeCost}
      </button>

      <button
        onClick={upgradeVisitorTimer}
        disabled={money < visitorTimerUpgradeCost}
        style={{
          fontSize: "16px",
          padding: "10px 15px",
          margin: "10px",
          cursor: money >= visitorTimerUpgradeCost ? "pointer" : "not-allowed",
        }}
        >
        Upgrade Besucher-Timer | {visitorTimerUpgradeCost}
      </button>
    </div>
  );
}
