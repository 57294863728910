// EndScreen.js
import { Html } from '@react-three/drei'

export default function EndScreen({ message }) {
    return (
        <>
            <div style={{ color: 'white', fontSize: '24px', textAlign: 'center', padding: '20px', background: 'rgba(0, 0, 0, 0.8)', borderRadius: '10px' }}>
                <h2>{message}</h2>
                
                <button onClick={() => window.location.reload()} style={{ padding: '10px 20px', marginTop: '20px' }}>
                    Nochmal spielen
                </button>
            </div>
        </>
    )
}
