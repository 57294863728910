// ShopWire.js
import React from 'react';
import Countdown from './Pattern/countdown';
import Scarcity from './Pattern/Scarcity';
import SocialProof from './Pattern/SocialProof';
import SneekIntoBasket from './Pattern/SneekInToBasket';

export default function ShopWire(props) {
  // Gemeinsame Styles als Konstanten definieren
  const containerStyle = {
    width: '1024px',
    backgroundColor: 'white',
    borderBottom: '1px solid #d5d5d5',
    userSelect: 'none'
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px',
    userSelect: 'none'
  };

  const navLinksStyle = {
    display: 'flex',
    gap: '64px',
    marginRight: '16px',
    userSelect: 'none'
  };

  const mainContentStyle = {
    padding: '0 80px',
  };

  const productSectionStyle = {
    display: 'flex',
    marginTop: '40px',
  };

  const productImageStyle = {
    height: '300px',
    flex: 1,
    display: 'flex',
  };

  const productDetailsStyle = {
    flex: 1,
    padding: '0 64px',
    textAlign: 'left'
  };

  const similarProductsContainerStyle = {
    marginTop: '80px',
  };

  const similarProductsGridStyle = {
    marginTop: '20px',
    display: 'flex',
    gap: '32px',
  };

  const similarProductStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const footerStyle = {
    display: 'flex',
    gap: '64px',
    marginTop: '80px',
    marginBottom: '80px',
    textAlign: 'left',
    borderTop: '1px solid #d5d5d5',
  };

  const footerItemStyle = {
    margin: 0,
  };

  // Interne Komponenten definieren
  const Header = () => (
    <div style={headerStyle}>
      <div>
        <p><b>Logo</b></p>
      </div>
      <div style={navLinksStyle}>
        <p>Home</p>
        <p>Shop</p>
        <p>About</p>
        <p>Warenkorb</p>
      </div>
    </div>
  );

  const Product = () => (
    <div style={productSectionStyle}>
      <div style={productImageStyle}>
        <div style={{ height: '100%', width: '100%', backgroundColor: 'gray' }}></div>
      </div>
      <div style={productDetailsStyle}>
      
        <Countdown target= {props.target[0]}/>

        <h1 style={{ margin: 0 }}>Produkt</h1>
        <p style={{ margin: 0 }}>⭐️⭐️</p>
        <h2 style={{ marginTop: '24px' }}>99€</h2>
        <p style={{ margin: 0 }}>Eine kurze Produktbeschreibung. Der noch von unserem Copy Writer später gefüllt wird.</p>
        <Scarcity target= {props.target[0]}/>
        <div style={{ display: 'flex', gap: '24px', marginTop: '12px'}}>
          <button style={{ padding: '16px 64px', backgroundColor: 'black', color: 'white', border: 'none' }}><b>Kaufen</b></button>
          <SneekIntoBasket target= {props.target[0]}/>
        </div>
        
      </div>
    </div>
  );

  const SimilarProduct = () => (
    <div style={similarProductStyle}>
      <div style={{ height: '124px', width: '80%', backgroundColor: 'gray' }}></div>
      <div style={{ width: '80%', textAlign: 'left', marginTop: '12px' }}>
        <h3 style={{ margin: '0' }}>Produkt</h3>
        {/* <p style={{ margin: 0 }}>⭐️⭐️⭐️⭐️</p> */}
        <p style={{ margin: '2px 0 0 0' }}>99€</p>
      </div>
    </div>
  );

  const SimilarProducts = () => (
    <div style={similarProductsContainerStyle}>
      <h2 >Ähnliche Produkte</h2>
      <div style={similarProductsGridStyle}>
        {[...Array(5)].map((_, index) => (
          <SimilarProduct key={index} />
        ))}
      </div>
    </div>
  );

  const Footer = () => (
    <div>
      
      <div style={footerStyle}>
        <h2 style={{ marginTop: '40px'}}>Footer</h2>
        <div style={{ marginTop: '40px'}}>
        <p style={footerItemStyle}>Main Menu</p>
        <p style={footerItemStyle}>Bliblala</p>
        <p style={footerItemStyle}>Dupidupi du</p>
        </div>
        <div style={{ marginTop: '40px'}}> 
          <p style={footerItemStyle}>Firma</p>
        </div>
        <div style={{ marginTop: '40px'}}> 
          <p style={footerItemStyle}>Impressum</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div style={containerStyle}>
        <Header />
        <div style={mainContentStyle}>
          <Product />
          <SocialProof target= {props.target[0]}/>
          <SimilarProducts />
          <Footer />
        </div>
      </div>
    </>
  );
}
