// HUD.js
import React from 'react'
import Chat from '../modules/Chat'

export default function HUD({
    levelName,
    levelQuest,
    onCheckAnswer,
    answeres,
    selectedAnswerIndex,
    onSelectAnswer,
    time
}) {
    
    return (
        <>  
            <div className='container' >
                <div className='menuHeader'>
                    <span><b>{levelName}</b></span>
                    
                </div>
                <div style={{
                    display: 'flex',
                    padding: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    gap: '4px',
                    flex: '1',

                    borderRadius: '8px',
                    border: '1px solid rgba(0, 153, 81, 1)',
                    background: 'rgba(207, 247, 211, 1)',
                }}>
                        <span>{time} Uhr</span>
                </div>
            </div>

            <div className='container'>
                <div className='menuHeader'>
                    <span><b>Aufgaben</b></span>
                    
                </div>
                <div className='menuSeperator'></div>

                <div className='menuItem'>
                    <div style={{
                        width: '1.25rem',
                        height: '1.25rem',
                        flexShrink: 0,
                        backgroundColor: 'black',
                    }}></div>

                    <div className='aufgabenInhalt'>

                        <div className='aufgabeTitel'>
                            <span>{levelQuest}</span>
                        </div>
                        
                        <span className='aufgabenBeschreibung'>Nutze den Countdown in allen Seiten</span>

                    </div>
                    
                    
                </div>
                <div className='menuItem'>
                    <div style={{
                        width: '1.25rem',
                        height: '1.25rem',
                        flexShrink: 0,
                        backgroundColor: 'black',
                    }}></div>

                    <div className='aufgabenInhalt'>

                        <div className='aufgabeTitel'>
                            <span>Countdown</span>
                        </div>
                        
                        <span className='aufgabenBeschreibung'>Nutze den Countdown in allen Seiten</span>

                    </div>
                    
                    
                </div>

                <div className='menuSeperator'></div>
                <button
                onClick={onCheckAnswer}
                disabled={selectedAnswerIndex === null}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    padding: '12px',

                    color: 'white',
                    backgroundColor: selectedAnswerIndex === null ? 'gray' : 'black',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: selectedAnswerIndex === null ? 'not-allowed' : 'pointer',
                    display: 'block',
                    opacity: selectedAnswerIndex === null ? 0.6 : 1,
                }}
            >
                Antwort prüfen
            </button>

            </div>
            {/* <Chat talk={levelTalk} level={levelName} /> */}
            {/* <h4>Aufgabe: {levelQuest}</h4> */}

            {/* Anzeige der Antworten als Buttons */}
            <div className='container' style={{height: '100%'}}>
            <div className='menuHeader'>
                    <span><b>Patterns</b></span>
                    
                </div>
                <div className='menuSeperator'></div>
                <div className='patternSection'>
                {answeres.map((answer, index) => (
                    <div className='patternItem'
                        key={index}
                        onClick={() => onSelectAnswer(index)}
                    >
                        <div style={{
                        width: '1.25rem',
                        height: '1.25rem',
                        flexShrink: 0,
                        backgroundColor: 'black',
                        borderRadius: '2rem'
                    }}></div>

                        {answer.label}
                    </div>
                ))}
                </div>
            </div>
       
        </>
    )
}
