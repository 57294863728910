export default function SneekIntoBasket(props){

    

    if(props.target == 'Sneak into Basket'){
        
        return <>
        <select name="choice" style={{ width: '100px', backgroundColor: 'none', color: 'black', border: 'none' }}>
              <option value="first">0</option>
              <option value="second" >1</option>
              <option value="third" selected>2</option>
          </select>
        </>
    } else {

        return <>
        <select name="choice" style={{ width: '100px', backgroundColor: 'none', color: 'black', border: 'none' }}>
              <option value="first">0</option>
              <option value="second" selected>1</option>
              <option value="third" >2</option>
          </select>
    </>
    }

    
}