export default function SocialProof(props){

    return <>
        <div
            style={{
            marginTop: '40px',
            textAlign: "center",
            fontWeight: "600",
            display: props.target === "Social Proof" ? "block" : "none",
            }}
        >
            
      <h2 >Bewertungen</h2>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {[...Array(4)].map((_, index) => (
          <div style={{ flex: 1}}>
          <div style={{ height: '64px', width: '64px', borderRadius: '64px', backgroundColor: 'gray' }}></div>
          <div style={{ width: '80%', textAlign: 'left', marginTop: '12px' }}>
            <p style={{ margin: '0' }}>Fake Persona</p>
            <p style={{ margin: 0 }}>⭐️⭐️⭐️⭐️</p>
            
          </div>
        </div>
        ))}
      </div>
    
      </div>
    </>
}