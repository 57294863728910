import React, { useRef, useState, useEffect } from 'react';
import HUD from '../organisms/Hud';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Button from '../atoms/Button';
import Modal from '../organisms/Modal';
import Target from '../atoms/Target';
import { useNavigate } from 'react-router-dom';
import FeedbackScreen from '../organisms/FeedBackScreen';

export default function Experience(props) {
  const { buttons, game, onGameFinished } = props;
  const navigate = useNavigate();
  const targetBoxRef = useRef();
  const windowsRefs = useRef([]);

  const levels = game.story;
  const levelKeys = Object.keys(levels);

  const [showExitModal, setShowExitModal] = useState(false);
  const [showFeedbackScreen, setShowFeedbackScreen] = useState(false);

  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [inTargetZone, setInTargetZone] = useState([false, false, false, false]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [targetBoxes, setTargetBoxes] = useState([]);


  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);

  const [dayEnd, setDayEnd] = useState(false);
  const [currentScore, setCurrentScore] = useState(0);
  const [anzahlFragen, setAnzahlFragen] = useState(0);


  const currentLevel = levels[levelKeys[currentLevelIndex]];
  const answeres = currentLevel.answeres;


  // ------------------------------
  // Virtuelle Zeit: 9:00 - 18:00 in 3 Minuten
  // Schritte von 20min alle ~6,666s
  const startTime = 9 * 60;   // 9:00 Uhr in Minuten (9*60=540)
  const endTime = 18 * 60;    // 18:00 Uhr in Minuten (18*60=1080)
  const increment = 20;       // 20 Minuten pro Schritt
  const totalSteps = (endTime - startTime) / increment; // 540/20 = 27 Schritte
  const totalRealTimeMs = 10000; // 3 Minuten = 180000ms
  const intervalMs = totalRealTimeMs / totalSteps; 
  

  const [virtualTime, setVirtualTime] = useState(startTime);

  useEffect(() => {
    if (showFeedbackScreen) return; // Bei Feedback-Bildschirm kein Weiterspringen der Zeit

    let currentTime = virtualTime;
    const timer = setInterval(() => {
      currentTime += increment;
      if (currentTime >= endTime) {
        currentTime = endTime;
        setVirtualTime(currentTime);
        clearInterval(timer);
        setDayEnd(true);
        
        console.log('Tag ist vorbei', dayEnd)
        return;
      } 
      setVirtualTime(currentTime);
    }, intervalMs);

    return () => {
      clearInterval(timer)
      setDayEnd(false)
    }
    
  }, [showFeedbackScreen, currentLevelIndex]); 
  // Bei neuem Level oder Feedbackscreen Neustart/Stop des Timers

  const formatTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
  // ------------------------------

  const onSelectAnswer = (index) => {
    setSelectedAnswerIndex(index);

    setInTargetZone((prev) => {
      const newState = prev.map(() => false);
      newState[index] = true;
      return newState;
    });

    setTargetBoxes([answeres[index].label]);
  };

  const checkAnswer = () => {
    if (selectedAnswerIndex === null) {
      console.log('Kein Element ausgewählt');
      setShowFeedbackScreen(true);
      setIsAnswerCorrect(false);
      return;
    }

    const selectedAnswer = answeres[selectedAnswerIndex];

    if (selectedAnswer.id === currentLevel.solution) {
      console.log('Richtige Antwort!');
      setIsAnswerCorrect(true);
      setCurrentScore((prevScore) => prevScore + 1)
      setAnzahlFragen((prevScore) => prevScore + 1)
    } else {
      console.log('Falsche Antwort. Versuche es erneut.');
      setIsAnswerCorrect(false);
      setAnzahlFragen((prevScore) => prevScore + 1)
    }
    // console.log("Anzahl richtige antworten",currentScore)
    // console.log("gesamte Antworten",anzahlFragen)

    if(dayEnd == true){
      setShowFeedbackScreen(true);
      return
    }

      // Neues Target hinzufügen
    const newTarget = {
      id: Date.now(), // Eindeutige ID für jedes Target
      position: [
        Math.random() * 2 - 1, // Zufällige Position x (-1 bis 1)
        Math.random() * 2 - 1, // Zufällige Position y (-1 bis 1)
        Math.random() * 2 - 1  // Zufällige Position z (-1 bis 1)
      ],
      scale: [1, 1, 0.1], // Standard-Skala
      label: selectedAnswer.label // Optional: Basierend auf der Antwort
    };

    // Target-Boxen aktualisieren
    setTargetBoxes((prevTargets) => [...prevTargets, newTarget]);
    
  };

  const handleNextLevel = () => {
    setShowFeedbackScreen(false); // Feedback-Bildschirm ausblenden

    if (isAnswerCorrect) {
      if (currentLevelIndex < levelKeys.length - 1) {
        setCurrentLevelIndex(currentLevelIndex + 1);
        resetLevelState();
      } else {
        console.log('Alle Level abgeschlossen!');
        onGameFinished(); // Spielende melden
      }
    } else {
      // Bei falscher Antwort: Level erneut versuchen
      resetLevelState();
    }
  };

  const resetLevelState = () => {
    setInTargetZone([false, false, false, false]);
    setSelectedAnswerIndex(null);
    windowsRefs.current = [];
    setTargetBoxes([]);
    setVirtualTime(startTime); // Zeit zurück auf 9:00 setzen
    setCurrentScore(0)
    setAnzahlFragen(0)
  };

  const handleBack = () => {
    setShowExitModal(true);
  };

  const confirmExit = () => {
    setShowExitModal(false);
    navigate('/'); // Zur Startseite navigieren
  };

  const cancelExit = () => {
    setShowExitModal(false);
  };

  return (
    <>
      {showFeedbackScreen ? (
        <FeedbackScreen score={currentScore} fragen={anzahlFragen} onNextLevel={handleNextLevel} />
      ) : (
        <>
          <div
            style={{
              height: '64px',
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #d5d5d5',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              alignItems: 'center',
              padding: '0 16px',
            }}
          >
            <Button href={buttons.homeButton.link} type="primary" onClick={handleBack}>
              {buttons.homeButton.label}
            </Button>
            <p>
              {currentLevel.name}/{levelKeys.length}
            </p>
          </div>

          <div style={{ display: 'flex', width: '100%', height: '100%' }}>

                        {/* HUD */}
                        <div
              style={{
                display: 'inline-flex',
                width: '20rem',
                height: '100%',
                padding: '0.75rem',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.5rem',
                flexShrink: 0
              }}
            >
              <HUD
                levelName={currentLevel.name}
                levelQuest={currentLevel.task}
                levelTalk={currentLevel.talk}
                score={500}
                inTargetZone={inTargetZone}
                onCheckAnswer={checkAnswer}
                answeres={answeres}
                selectedAnswerIndex={selectedAnswerIndex}
                onSelectAnswer={onSelectAnswer}
                time={formatTime(virtualTime)}
                // Falls Sie die Zeit an die HUD weitergeben möchten, könnten Sie hier:
                // virtualTime={virtualTime}
              />
            </div>

            {/* Canvas */}
            <Canvas style={{ width: '70%', height: '100%'}}>
              <OrbitControls
                makeDefault
                minDistance={3}
                maxDistance={4}
                minPolarAngle={1}
                maxPolarAngle={2}
                minAzimuthAngle={0}
              />

              {/* Rendern des Ziels */}
              <Target
                ref={targetBoxRef}
                position={[0, 0, 0]}
                scale={[1, 1, 0.1]}
                target={targetBoxes}
              />
            </Canvas>

          </div>
        </>
      )}

      {/* Modal für Spiel beenden */}
      {showExitModal && (
        <Modal
          title="Spiel beenden"
          message="Möchten Sie das Spiel wirklich beenden?"
          onConfirm={confirmExit}
          onCancel={cancelExit}
        />
      )}
    </>
  );
}
