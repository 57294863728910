export default function TopBarWindow() {

    return <>
        <div
                    style={{
                        height: '32px',
                        width: '100%',
                        backgroundColor: 'rgba(100, 100, 100, 0.10)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',

                        
                    }} 
                >
                    <div style={ { display: 'flex', gap: '8px', marginLeft: '16px', marginTop: '12px' } }>
                        <div className="dot" style={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: 'red' }}></div>
                        <div className="dot" style={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: 'orange' }}></div>
                        <div className="dot" style={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                    </div>
                    <div style={ { height: '16px', width: '360px', marginTop: '8px',  border: "1px solid rgba(100, 100, 100, 0.40)", borderRadius: '4px' }}></div>
                    <div style={ { height: '16px', width: '16px' }}></div>
                </div>
    </>
}