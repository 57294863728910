import React, { useState, useEffect } from "react";

export default function Countdown({
  purchaseChance,
  setPurchaseChance,
}) {
  const [isCountdownActive, setIsCountdownActive] = useState(false); // Status für den Countdown
  const [countdown, setCountdown] = useState(30); // Countdown-Timer in Sekunden
  const countdownChance = 0.3

  // Funktion zum Aktivieren des Countdown-Buttons
  const activateCountdown = () => {
    if (!isCountdownActive) {
      setIsCountdownActive(true); // Aktiviert den Countdown
      setPurchaseChance((prev) => prev + countdownChance); // Erhöht die Kaufwahrscheinlichkeit um 0.10%
    }
  };

  // Countdown-Logik
  useEffect(() => {
    let timer;
    if (isCountdownActive) {
      // Countdown läuft
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev > 1) {
            return prev - 1; // Verringert den Countdown
          } else {
            // Timer abgelaufen
            clearInterval(timer); // Stoppt den Timer
            setIsCountdownActive(false); // Deaktiviert den Countdown
            setCountdown(30); // Setzt den Countdown zurück
            setPurchaseChance((prev) => prev - countdownChance); // Setzt die Kaufwahrscheinlichkeit zurück
            return 30; // Zurück auf 30 Sekunden
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer); // Bereinigt den Timer bei Komponentendeaktivierung
  }, [isCountdownActive, setPurchaseChance]);

  return (
    <button
      onClick={activateCountdown}
      disabled={isCountdownActive} // Button ist deaktiviert, wenn der Countdown aktiv ist
      style={{
        fontSize: "16px",
        padding: "10px 15px",
        margin: "10px",
        cursor: isCountdownActive ? "not-allowed" : "pointer",
      }}
    >
      {isCountdownActive ? `Countdown: ${countdown}s` : `Activate Countdown (+ ${countdownChance} %)`}
    </button>
  );
}
