import React, { useState, useEffect, useRef } from 'react';
import FlexTest from './FlexTest';

export default function GameWithStartScreen() {
  const [showStartScreen, setShowStartScreen] = useState(true); // Start-Screen anzeigen
  const [showInfoPopup, setShowInfoPopup] = useState(false); // Info-Popup anzeigen
  const [showLoading, setShowLoading] = useState(false); // Ladebalken anzeigen
  const [showGame, setShowGame] = useState(false); // Ladebalken anzeigen
  const intervalRef = useRef(null);
  const boxRef = useRef(null);


  const handleStart = () => {
    setShowStartScreen(false);
    setShowLoading(true);

    setTimeout(() => {
      setShowLoading(false);
      
      setShowGame(true);
    }, 2000); // Ladebalken für 2 Sekunden anzeigen

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    removeAllSVGs();
  };

  const toggleInfoPopup = () => setShowInfoPopup(prev => !prev);


  function spawnSVG({
    container = document.body,
    boxElement,
    spawnDuration = 40000,
    speed = 1,
    gravityStrength = 0.02,
    gravityRadius = 100,
    onCollision,
  }) {
    const svgDiv = document.createElement("div");
    svgDiv.className = "svg-icon";
    svgDiv.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M4 0l16 12.279-6.951 1.17 4.325 8.817-3.596 1.734-4.35-8.879-5.428 4.702z"/>
      </svg>
    `;
  
    let x = Math.random() * (window.innerWidth - 50);
    let y = Math.random() * (window.innerHeight - 50);
  
    svgDiv.style.position = "absolute";
    svgDiv.style.left = `${x}px`;
    svgDiv.style.top = `${y}px`;
    container.appendChild(svgDiv);
  
    let dx = Math.random() < 0.5 ? -1 : 1;
    let dy = Math.random() < 0.5 ? -1 : 1;
  
    const removeTimer = setTimeout(() => {
      if (svgDiv.isConnected) {
        svgDiv.remove();
      }
    }, spawnDuration);
  
    function moveSVG() {
      let angle = Math.atan2(dy, dx);
      angle += (Math.random() - 0.5) * 0.3;
      dx = Math.cos(angle);
      dy = Math.sin(angle);
  
      x += dx * (speed + (Math.random() - 0.3));
      y += dy * (speed + (Math.random() - 0.3));
  
      if (boxElement) {
        const boxRect = boxElement.getBoundingClientRect();
        const cx = boxRect.left + boxRect.width / 2;
        const cy = boxRect.top + boxRect.height / 2;
  
        let gx = cx - x;
        let gy = cy - y;
        const dist = Math.sqrt(gx * gx + gy * gy);
  
        if (dist > 20 && dist < gravityRadius) {
          const force = gravityStrength * (1 - dist / gravityRadius);
          gx /= dist;
          gy /= dist;
          dx += gx * Math.min(force, 0.2);
          dy += gy * Math.min(force, 0.2);
        }
  
        const svgRect = svgDiv.getBoundingClientRect();
        if (
          svgRect.left < boxRect.right &&
          svgRect.right > boxRect.left &&
          svgRect.top < boxRect.bottom &&
          svgRect.bottom > boxRect.top
        ) {
          clearTimeout(removeTimer);
          svgDiv.remove();
          if (onCollision) onCollision();
          return;
        }
      }
  
      if (x <= 0 || x >= window.innerWidth - 50) dx *= -1;
      if (y <= 0 || y >= window.innerHeight - 50) dy *= -1;
  
      svgDiv.style.left = `${x}px`;
      svgDiv.style.top = `${y}px`;
  
      if (svgDiv.isConnected) {
        requestAnimationFrame(moveSVG);
      } else {
        clearTimeout(removeTimer);
      }
    }
  
    moveSVG();
  }

  function removeAllSVGs() {
    const svgElements = document.querySelectorAll('.svg-icon');
    svgElements.forEach(svg => svg.remove());
  }
  
  
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      spawnSVG({
        container: document.body,
        boxElement: null,
        spawnDuration: 40000,
        speed: Math.random() * (2 - 1) + 1,
        gravityStrength: 0.02,
        gravityRadius: 100,
        onCollision: () => console.log("Collision detected!"),
      });
    }, 4000);
  
    return () => {
      clearInterval(intervalRef.current); // Intervall beim Unmount stoppen
    };
  }, []);
  
  
  console.log('showStartScreen', showStartScreen);
  return (
    <>
    
      {/* Start-Screen */}
      {showStartScreen && (
        <div
          style={{
            position: 'absolute',
            
            left: '50%',
            width: 'auto',
            height: '100%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 100,
          }}
        >
          <h1 style={{ color: 'black', fontSize: '200px', lineHeight: 1 ,margin: 0}}>Dark Pattern</h1>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 32 ,width: '100%', height: 'auto'}}>
          <p style={{ fontSize: '2rem', marginBottom: '20px', color: 'black', fontWeight: '500'}}>
          Von Tricks zur Täuschung! Sei manipulativ! Steigere deine Verkäufe mit Dark Pattern.
          </p>
          <button
            onClick={handleStart}
            style={{
              padding: '0 16px',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              backgroundColor: '#009951',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              marginBottom: '20px',
              height: 48,
            }}
          >
            Start
          </button>
          </div> 
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start', // Hier geändert
              width: '100%',
              height: 'auto',
            }}
          >
            <a
              onClick={toggleInfoPopup}
              style={{
                padding: '8px 16px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: 'none',
                color: 'black',
                border: 'none',
                cursor: 'pointer',
                textDecoration: 'underline',
                paddingBottom: '40px',
              }}>
              Info
            </a>
          </div>

          

          {/* Info-Popup */}
          {showInfoPopup && (
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                

                backgroundColor: '#fff',
                color: 'black',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                
              }}
            >
              <div style={{height: '100%', width: '100%', backgroundColor: '#fff', position: 'rlertive'}}>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '60%', position: 'absolute', left: '50%', transform: 'translate(-50%)'}}>
                <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                <h1>Was ist ein Dark Pattern?</h1>
                            <p>
                            
                        In dem Spiel nimmst du die Rolle eines Designers ein, der sich skrupellos der Kunst der Manipulation bedient. Dark Patterns sind Tricks, die in digitalen Interfaces eingesetzt werden, um Nutzer zu Entscheidungen zu verleiten, die oft nicht in ihrem Interesse sind. Dabei nutzt du psychologische Schwächen der Spieler aus – von künstlicher Dringlichkeit bis hin zu versteckten Kosten. Dein Ziel ist es, herauszufinden, wie diese Taktiken funktionieren und wie sie sich auf das Verhalten auswirken.
                        </p>
                </div>
                <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                    <h1>Wie funktioniert das Spiel?</h1>
                    <p>
                        Das Spiel zeigt dir Schritt für Schritt, wie manipulative Designs die Entscheidungen von Nutzern beeinflussen können. Dein Ziel ist es, so viele Verkäufe wie möglich zu erzielen, indem du Dark Patterns gezielt einsetzt. Gleichzeitig lernst du die Mechanismen hinter diesen Strategien kennen, um ein Bewusstsein für ihre Wirkung zu entwickeln.
                     </p>
              </div>
              <button
                onClick={toggleInfoPopup}
                style={{
                  marginTop: '10px',
                  padding: '8px 16px',
                  backgroundColor: '#009951',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Schließen
              </button>
            </div>
            </div>
            
            </div>
          )}
        </div>
      )}

      {/* Ladebalken */}
      {showLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '60%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',

          }}
        >
          <h1>Level 1</h1>
          <div
            style={{
              width: '100%',
              height: '20px',
              backgroundColor: '#ccc',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#D32F2F',
                animation: 'loading 2s linear',
              }}
            ></div>
          </div>
        </div>
      )}

      {/* Hauptspiel */}
      {showGame && <FlexTest />}

      <style>
        {`
          @keyframes loading {
            from {
              width: 0%;
            }
            to {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
}
